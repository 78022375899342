import { Axios } from "../../utils/axiosMiddleware";
import { SimpleCatchError } from "../../utils/Helper";

export const subscriberTrial = async (dispatch) => {
  try {
    const token = JSON.parse(localStorage.getItem("data"));
    const response = await Axios.get(`subscribers/user/${token?.user_id}`);
    return response?.data?.type;
  } catch (e) {
    SimpleCatchError(e);
  }
};
