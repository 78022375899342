import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Select,
  Upload,
} from "antd";
import {
  MinusCircleOutlined,
  EnvironmentOutlined,
  DollarOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import Header from "../../components/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/GeneralComponents";
import { BackButton } from "../../components/General/BackButton";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import { GetAdminSkills } from "../../api/contractsApi/getSkillsApi";
import AddSkillModal from "../../components/Contracts/AddSkillModal";
import LocationSearch from "../../components/AutoCompleteLocation/AutoCompleteLocation";
import { GigmiUserModal } from "../../components/Contracts/GigmiUserModal";
import Disclaimer from "../../components/BankInfo/Disclaimer";
import { useNavigate } from "react-router";
import { CreateHamBurgContractRequest } from "../../api/contractsApi/createContractFromhamburg";
import { GenerateGigmiCharges } from "../../api/contractsApi/contractDetails";
import ContractAmountDetailsModal from "../../components/Contracts/ContractAmountDetailModal";
import { CreateQuickContract } from "../../api/contractsApi/createContract";
import { GetUserListRequest } from "../../api/contractsApi/getContracts";
import { UserList } from "../../components/Contracts/GigsterListingModal";
import { setSelectedUser } from "../../actions/ContractActions";
import { google_map_api_key } from "../../constants";

const SendEstimatiionswithOptions = (props) => {
  const searchParams = new URLSearchParams(window.location.search);
  const path = searchParams.get("path");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const userData = JSON.parse(localStorage.getItem("data"));
  const userTypeData = useSelector((state) => state?.common?.newUser?.data);
  const fullUser = useSelector((state) => state?.common?.fullUser);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [address, setAddress] = useState("");
  const [coordinates, setCoordinates] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [showModal, setShowModal] = useState(true);
  const [gigmiUser, setGigmiUser] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [startDate, setStartDate] = useState();
  const [senderVerification, setSenderVerification] = useState(
    !fullUser?.sendingAccountVerify
  );
  const [receiverVerification, setReceiverVerification] = useState(
    !fullUser?.receivingBankAccountVerify
  );
  const [hideshowemail, setHideshowEmail] = useState("email");
  const [locationLoading, setLocationLoading] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [skillOptions, setSkillOptions] = useState([]);
  const [contractedType, setContractedType] = useState(
    userData?.user_type === "contractor" ? "homeowner" : ""
  );
  const isTypeHomeowner = [contractedType, userData?.user_type].includes(
    "homeowner"
  );
  const gigmiCharges = useSelector((state) => state?.gigmiCharges?.charges);
  const [confirmChargesModal, setConfirmChargesModal] = useState(false);
  const [maxAmount, setMaxAmount] = useState(false);
  const [selectPhone, setSelectPhone] = useState(false);
  const [userListModal, setUserListModal] = useState(false);
  const [selectedUserdata, setSelectedUserdata] = useState(false);

  const { userList, selectedUser } = useSelector((state) => state?.contracts);

  const getSkills = async () => {
    const res = await GetAdminSkills();
    setSkillOptions(res);
  };
  useEffect(() => {
    form.resetFields();
    setShowModal(true);
    setGigmiUser(false);
  }, [path]);
  useEffect(() => {
    form.resetFields(["location"]);
  }, [locationLoading]);
  useEffect(() => {
    form.resetFields(["eDate"]);
  }, [startDate]);

  useEffect(() => {
    getSkills();
    if (path === "quick" && userData?.user_type === "tradesman") {
      setContractedType("contractor");
    }
    if (userData?.user_type !== "tradesman") {
      const body = {
        homeOwnerId:
          userData?.user_type === "contractor" ? null : userTypeData?.id,
        contractorId:
          userData?.user_type === "contractor" ? userTypeData?.id : null,
      };
      GenerateGigmiCharges(body, dispatch);
    }
  }, []);
  const handleGetLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          setCoordinates({
            lat,
            lng,
          });

          axios(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${google_map_api_key}`
          )
            .then((data) => {
              if (data.data.results && data.data.results.length > 0) {
                setAddress(data?.data?.results[0]?.formatted_address);
              }
              setLocationLoading(!locationLoading);
            })
            .catch((error) => {
              console.error(error);
            });
        },
        (error) => {
          message.error("Error getting location:", error);
        }
      );
    } else {
      message.error("Geolocation is not supported in this browser.");
    }
  };

  const handleSubmit = async (values) => {
    const items = values?.lineItems?.map((item) => {
      return {
        job_desc: item?.lineItem,
        flat_rate: item?.flatRate,
        isChecked: true,
      };
    });
    if (path !== "quick") {
      const email =
        gigmiUser && values?.username?.includes("@")
          ? values.username
          : values.email?.trim()?.toLowerCase();
      const phone =
        gigmiUser && !values?.username?.includes("@")
          ? values.username
          : values.phoneNumber
          ? "+" + values.phoneNumber.replace(/\D/g, "")
          : null;

      const body = {
        first_name: values.name,
        last_name: values.lastName,
        email: email,
        phone: phone,
        user_contracted_type: "homeowner",
        skill: null,
        user_skill: values.skill,
        user_id: userTypeData?.id,
        user_current_type: userData?.user_type,
        tradesman_ids: [],
        line_item: items,
        flat_amount: values.totalAmount,
        start_date: values.sDate,
        end_date: values.eDate,
        latitude: coordinates?.lat,
        longitude: coordinates?.lng,
        location_name: address,
        status: path === "contract" ? "contract_given" : "estimation_given",
        isTemplate: false,
        send_via: values.send ? values.send : hideshowemail,
        IsNewUser: !gigmiUser,
      };
      setLoading(true);
      await CreateHamBurgContractRequest(body, fileList, navigate, dispatch);
      setLoading(false);
    } else if (path === "quick") {
      const email =
        gigmiUser && values?.username?.includes("@")
          ? values.username
          : values.email?.trim()?.toLowerCase();
      const phone =
        gigmiUser && !values?.username?.includes("@")
          ? values.username
          : values.phoneNumber
          ? "+" + values.phoneNumber.replace(/\D/g, "")
          : null;
      const body = {
        first_name: values?.name,
        last_name: values?.lastName,
        email: email,
        phone: phone,
        userType:
          userData?.user_type === "tradesman"
            ? "contractor"
            : values?.contracted_type,
        skill: values.skill,
        line_item: isTypeHomeowner ? items : null,
        job_desc: isTypeHomeowner ? null : values?.jobdesc,
        flat_amount: values?.totalAmount,
        latitude: coordinates?.lat,
        longitude: coordinates?.lng,
        location_name: address,
        send_via: values.send ? values.send : hideshowemail,
        start_date: values.sDate,
        end_date: values.eDate,
        IsNewUser: !gigmiUser,
        contractorId:
          userData?.user_type === "contractor" ? userTypeData?.id : null,
        tradesmanId:
          userData?.user_type === "tradesman" ? userTypeData?.id : null,
        homeownerId:
          userData?.user_type === "homeowner" ? userTypeData?.id : null,
        gigmi_charges:
          userData?.user_type === "tradesman" ? null : gigmiCharges,
      };
      setFormValues(body);
      if (userData?.user_type === "tradesman") {
        QuickContract(body);
      } else {
        setConfirmChargesModal(true);
      }
    }
  };
  const QuickContract = async (body) => {
    setLoading(true);
    await CreateQuickContract(body, fileList, navigate, dispatch);
    setLoading(false);
  };
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const [totalPrice, setTotalPrice] = useState(0);

  const onValuesChange = (_, values) => {
    if (values?.lineItems?.length > 0) {
      const { lineItems } = values;
      const newTotalPrice = lineItems.reduce(
        (acc, item) => acc + Number(item.flatRate || 0),
        0
      );
      setTotalPrice(newTotalPrice.toString());
      form.setFieldsValue({
        totalAmount: newTotalPrice.toString(),
      });
    } else if (values?.lineItems?.length === 0) {
      form.setFieldsValue({
        totalAmount: "0",
      });
    }
  };
  const validateTotalAmount = (_, value) => {
    if (Number(value) > 5000) {
      return Promise.reject(
        `${props.intl.formatMessage({
          id: "total_amount_should",
        })}`
      );
    }
    return Promise.resolve();
  };
  const disabledDate = (current) => {
    if (path !== "quick") {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      return current && current < currentDate;
    } else {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const nextDay = new Date(currentDate);
      nextDay.setDate(currentDate.getDate() + 1);
      return current && current >= nextDay;
    }
  };
  const disabledEndDate = (current) => {
    if (path !== "quick") {
      return current && current < startDate;
    } else {
      const currentDate = new Date();
      currentDate.setHours(23, 59, 59, 999);
      return current && (current < startDate || current > currentDate);
    }
  };
  const onFileChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  return (
    <>
      {loading && (
        <div className="search-loader">
          <Loader />
        </div>
      )}

      <BackButton />
      <div className="container paymentscreen">
        <Header />
        <div className="paymentscreen-inner">
          <div className="d-flex justify-content-center align-items-center getting-payment-heading shadow border text-center">
            <span>
              {path === "contract" ? (
                <FormattedMessage id="create_contract" />
              ) : path === "estimate" ? (
                <FormattedMessage id="create_estimate" />
              ) : (
                path === "quick" && <FormattedMessage id="quick_contract" />
              )}
            </span>
          </div>
          <Form
            onFinish={handleSubmit}
            onValuesChange={onValuesChange}
            form={form}
            layout="vertical"
            initialValues={{
              name: "",
              lastName: "",
              username: "",
              email: "",
              phoneNumber: "",
              skill: "",
              lineItems: "",
              flatRate: "",
              totalAmount: path === "quick" ? "" : totalPrice,
              sDate: "",
              eDate: "",
              location: location ? location?.address : "",
              upload: "",
              sendVia: "",
            }}
          >
            {!gigmiUser && (
              <>
                <div className="row">
                  <div className="col-6">
                    <Form.Item
                      name="name"
                      label={props.intl.formatMessage({ id: "first_name" })}
                      rules={[
                        {
                          required: true,
                          message: `${props.intl.formatMessage({
                            id: "required",
                          })}`,
                        },
                      ]}
                    >
                      <Input
                        placeholder={`${props.intl.formatMessage({
                          id: "first_name",
                        })}`}
                        className="input-field"
                      />
                    </Form.Item>
                  </div>
                  <div className="col-6">
                    <Form.Item
                      name="lastName"
                      label={props.intl.formatMessage({ id: "last_name" })}
                      rules={[
                        {
                          required: true,
                          message: `${props.intl.formatMessage({
                            id: "last_name",
                          })}`,
                        },
                      ]}
                    >
                      <Input
                        placeholder={`${props.intl.formatMessage({
                          id: "last_name",
                        })}`}
                        className="input-field"
                      />
                    </Form.Item>
                  </div>
                </div>
              </>
            )}
            {path === "quick" && userData?.user_type !== "tradesman" && (
              <Form.Item
                name="contracted_type"
                rules={[
                  {
                    required: true,
                    message: `${props.intl.formatMessage({
                      id: "please_select",
                    })}`,
                  },
                ]}
              >
                <Select
                  placeholder={`${props.intl.formatMessage({
                    id: "SELECT_USER_TYPE",
                  })}`}
                  onChange={(value) => setContractedType(value)}
                >
                  {userData?.user_type !== "homeowner" && (
                    <Select.Option value="homeowner">Homeowner</Select.Option>
                  )}
                  {userData?.user_type !== "contractor" && (
                    <Select.Option value="contractor">Contractor</Select.Option>
                  )}
                  {userData?.user_type !== "tradesman" && (
                    <Select.Option value="tradesman">Gigster</Select.Option>
                  )}
                </Select>
              </Form.Item>
            )}
            {!gigmiUser && (
              <>
                <Form.Item
                  name="email"
                  label={props.intl.formatMessage({ id: "email" })}
                  rules={[
                    {
                      type: "email",
                      pattern: new RegExp(
                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|io|org|net|edu|gov|mil|co|ac|biz|info|me|name|tv|us|ca|uk|co\.uk)$/
                      ),

                      message: `${props.intl.formatMessage({
                        id: "valid_email",
                      })}`,
                    },
                    {
                      required: true,
                      message: `${props.intl.formatMessage({
                        id: "email_required",
                      })}`,
                    },
                  ]}
                >
                  <Input
                    type="email"
                    placeholder={`${props.intl.formatMessage({ id: "email" })}`}
                    className="input-field"
                  />
                </Form.Item>

                <Form.Item
                  name="phoneNumber"
                  label={props.intl.formatMessage({ id: "phone_number" })}
                  rules={[
                    {
                      required: true,
                      message: `${props.intl.formatMessage({
                        id: "required",
                      })}`,
                    },
                    {
                      pattern: new RegExp(/^[0-9]*(?:\.[0-9]*)?$/),
                      message: `${props.intl.formatMessage({
                        id: "only_numeric",
                      })}`,
                    },
                  ]}
                >
                  <PhoneInput
                    inputProps={{
                      name: "phone",
                      required: true,
                    }}
                    countryCodeEditable={false}
                    inputStyle={{
                      width: "100%",
                      height: "56px",
                      backgroundColor: "#E6E7EB",
                      marginTop: "8px",
                    }}
                    country={"us"}
                    className="w-100 phonenumber_field countries"
                    length="10"
                    inputClass="contact_field"
                  />
                </Form.Item>
              </>
            )}
            {gigmiUser && (
              <>
                <Form.Item
                  name="username"
                  label={props.intl.formatMessage({ id: "username" })}
                  rules={[
                    {
                      required: true,
                      message: `${props.intl.formatMessage({
                        id: "username_required",
                      })}`,
                    },
                  ]}
                >
                  <div
                    className="input-field input-button-field"
                    onClick={async () => {
                      if (contractedType) {
                        setUserListModal(true);
                        await GetUserListRequest(
                          {
                            searchText: "",
                            userSearchType: contractedType,
                          },
                          dispatch
                        );
                      } else {
                        message.error(
                          props.intl.formatMessage({
                            id: "Please select a user type first",
                          })
                        );
                      }
                    }}
                  >
                    {form.getFieldValue("username")
                      ? form.getFieldValue("username")
                      : props.intl.formatMessage({
                          id: "Enter Email or Mobile Number",
                        })}
                  </div>
                </Form.Item>
              </>
            )}

            <Form.Item
              name="skill"
              rules={[
                {
                  required: true,
                  message: `${props.intl.formatMessage({
                    id: "select_skill",
                  })}`,
                },
              ]}
            >
              <div className="row w-100">
                <span className="col-6">
                  <FormattedMessage id="skill" />{" "}
                </span>
                <div className="col-6 d-flex justify-content-end">
                  <span
                    style={{
                      color: "#F77E0B",
                      fontFamily: "Poppins",
                      cursor: "pointer",
                    }}
                    onClick={() => setModalShow(true)}
                  >
                    {props.intl.formatMessage({ id: "addNewSkill" })}
                  </span>
                </div>
              </div>
              <Select
                placeholder={`${props.intl.formatMessage({
                  id: "select_skill",
                })}`}
                onChange={(value) => form.setFieldsValue({ skill: value })}
              >
                {skillOptions.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {(path !== "quick" || (path === "quick" && isTypeHomeowner)) && (
              <Form.List name="lineItems">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <div className="row">
                        <div className="col-8">
                          <Form.Item
                            {...restField}
                            name={[name, "lineItem"]}
                            label={`${props.intl.formatMessage({
                              id: "line_item",
                            })}`}
                            key={[key + "lineItem"]}
                            rules={[
                              {
                                required: true,
                                message: `${props.intl.formatMessage({
                                  id: "missing_line_item",
                                })}`,
                              },
                            ]}
                          >
                            <Input
                              placeholder={`${props.intl.formatMessage({
                                id: "add_new_line_item",
                              })}`}
                              className="input-field"
                            />
                          </Form.Item>
                        </div>
                        <div className="col-3">
                          <Form.Item
                            {...restField}
                            name={[name, "flatRate"]}
                            key={[key + "flatRate"]}
                            label={`${props.intl.formatMessage({
                              id: "flat_rate",
                            })}`}
                            rules={[
                              {
                                required: true,
                                message: `${props.intl.formatMessage({
                                  id: "missing_flat_rate",
                                })}`,
                              },
                            ]}
                          >
                            <Input
                              type="number"
                              placeholder={`${props.intl.formatMessage({
                                id: "flat_rate",
                              })}`}
                              className="input-field"
                              prefix={<DollarOutlined />}
                            />
                          </Form.Item>
                        </div>
                        <div className="col-1 d-flex justify-content-center align-items-center">
                          <MinusCircleOutlined
                            style={{ height: "auto", fontSize: 18 }}
                            onClick={() => remove(name)}
                          />
                        </div>
                      </div>
                    ))}

                    <Button className="input-field mb-3" onClick={add} block>
                      <span style={{ color: "black" }}>
                        <PlusOutlined style={{ paddingRight: "10px" }} />
                        {`${props.intl.formatMessage({
                          id: "add_line_items",
                        })}`}
                      </span>
                    </Button>
                  </>
                )}
              </Form.List>
            )}
            {path === "quick" && !isTypeHomeowner && (
              <Form.Item
                name="jobdesc"
                label={`${props.intl.formatMessage({
                  id: "job_desc",
                })}`}
                rules={[
                  {
                    required: true,
                    message: `${props.intl.formatMessage({
                      id: "required",
                    })}`,
                  },
                ]}
              >
                <Input.TextArea
                  disabled={path === "estimation"}
                  placeholder={`${props.intl.formatMessage({
                    id: "job_desc",
                  })}`}
                  type="tdext"
                  className="input-field"
                  rows={4}
                  style={{ padding: 20, resize: "none" }}
                />
              </Form.Item>
            )}
            <div
              className="d-flex justify-content-end"
              style={{ position: "relative" }}
            >
              <div
                style={{
                  height: "20px",
                  cursor: "pointer",
                  position: "absolute",
                  zIndex: 999,
                }}
                onClick={() => setMaxAmount(true)}
              >
                <ExclamationCircleOutlined />
              </div>
            </div>
            <Form.Item
              name="totalAmount"
              label={
                path === "quick"
                  ? `${props.intl.formatMessage({
                      id: "flat_rate",
                    })}`
                  : `${props.intl.formatMessage({
                      id: "total_amount",
                    })}`
              }
              rules={[
                {
                  required: true,
                  message: `${props.intl.formatMessage({
                    id: "required",
                  })}`,
                },
                { validator: validateTotalAmount },
              ]}
            >
              <Input
                type="number"
                placeholder={
                  path === "quick"
                    ? `${props.intl.formatMessage({
                        id: "flat_rate",
                      })}`
                    : `${props.intl.formatMessage({
                        id: "total_amount",
                      })}`
                }
                className="input-field"
                prefix={<DollarOutlined />}
              />
            </Form.Item>

            <div className="row">
              <div className="col-6">
                <Form.Item
                  name="sDate"
                  label={`${props.intl.formatMessage({
                    id: "start_date",
                  })}`}
                  rules={[
                    {
                      required: true,
                      message: `${props.intl.formatMessage({
                        id: "required",
                      })}`,
                    },
                  ]}
                >
                  <DatePicker
                    disabledDate={disabledDate}
                    className="input-field"
                    placeholder={`${props.intl.formatMessage({
                      id: "start_date",
                    })}`}
                    onChange={(e) => setStartDate(e)}
                  />
                </Form.Item>
              </div>
              <div className="col-6">
                <Form.Item
                  name="eDate"
                  label={`${props.intl.formatMessage({
                    id: "end_date",
                  })}`}
                  rules={[
                    {
                      required: true,
                      message: `${props.intl.formatMessage({
                        id: "required",
                      })}`,
                    },
                  ]}
                >
                  <DatePicker
                    disabledDate={disabledEndDate}
                    disabled={startDate ? false : true}
                    className="input-field"
                    placeholder={`${props.intl.formatMessage({
                      id: "end_date",
                    })}`}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="row ">
              <div className="col-12">
                <div className="d-flex justify-content-between">
                  <div>
                    <span className="email-text">
                      {props.intl.formatMessage({ id: "location" })}
                    </span>
                  </div>
                  <div onClick={handleGetLocation}>
                    <span>
                      <EnvironmentOutlined style={{ color: "#F77E0B" }} />
                      <span
                        className="trad-location ms-1"
                        style={{ userSelect: "none" }}
                      >
                        {props.intl.formatMessage({ id: "current_location" })}
                      </span>
                    </span>
                  </div>
                </div>

                <Form.Item
                  name="location"
                  rules={[
                    {
                      required: false,
                      message: `${props.intl.formatMessage({
                        id: "location_required",
                      })}`,
                    },
                  ]}
                >
                  <LocationSearch
                    address={address}
                    setAddress={setAddress}
                    coordinates={coordinates}
                    setCoordinates={setCoordinates}
                  />
                </Form.Item>
              </div>
            </div>
            <Form.Item
              label={`${props.intl.formatMessage({
                id: "upload",
              })}`}
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload
                accept=".jpeg,.pdf"
                listType="picture-card"
                maxCount={4}
                onChange={onFileChange}
              >
                <div
                  style={{
                    width: "25px",
                    height: "25px",
                    backgroundColor: "#F77E0B",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <PlusOutlined style={{ color: "white" }} />
                </div>
              </Upload>
            </Form.Item>
            {!gigmiUser && (
              <>
                <div
                  className="d-flex justify-content-end"
                  style={{ position: "relative" }}
                >
                  <div
                    style={{
                      height: "20px",
                      cursor: "pointer",
                      position: "absolute",
                      zIndex: 999,
                    }}
                    onClick={() => setSelectPhone(true)}
                  >
                    <ExclamationCircleOutlined />
                  </div>
                </div>

                <Form.Item
                  name="send"
                  label={`${props.intl.formatMessage({
                    id: "send_via",
                  })}`}
                  rules={[
                    {
                      required: true,
                      message: `${props.intl.formatMessage({
                        id: "required",
                      })}`,
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio value="email">
                      <FormattedMessage id="email" />{" "}
                    </Radio>
                    <Radio value="phone">
                      <FormattedMessage id="sms" />{" "}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </>
            )}

            <div className="d-flex justify-content-end">
              <Form.Item>
                <Button
                  type="submit"
                  htmlType="submit"
                  className="submit-button"
                >
                  <FormattedMessage id="submit" />
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
        <div>
          <AddSkillModal
            modalShow={modalShow}
            setModalShow={setModalShow}
            skillArray={skillOptions}
            selectedSkills={selectedSkills}
            setSkillArray={setSkillOptions}
            setSelectedSkills={setSelectedSkills}
          />
          <GigmiUserModal
            showModal={showModal}
            setShowModal={() => setShowModal(false)}
            setGigmiUser={setGigmiUser}
          />
          <Disclaimer
            showModal={
              senderVerification && (path === "contract" || path === "quick")
            }
            setShowModal={() => setSenderVerification(false)}
            body={<FormattedMessage id="receiver_account" />}
            cancelText={<FormattedMessage id="no" />}
            confirmText={<FormattedMessage id="yes" />}
            handleClick={() => navigate("/payment-options")}
            handleCancel={() => navigate("/")}
          />
          <Disclaimer
            showModal={
              receiverVerification &&
              (path === "estimate" || path === "quick") &&
              userData?.user_type !== "homeowner"
            }
            setShowModal={() => setReceiverVerification(false)}
            body={<FormattedMessage id="sender_account" />}
            cancelText={<FormattedMessage id="no" />}
            confirmText={<FormattedMessage id="yes" />}
            handleClick={() => navigate("/payment-options")}
            handleCancel={() => navigate("/")}
          />
          <Disclaimer
            showModal={maxAmount}
            setShowModal={() => setMaxAmount(false)}
            body={<FormattedMessage id="contract_max" />}
            confirmText={<FormattedMessage id="ok" />}
            handleClick={() => setMaxAmount(false)}
          />
          <Disclaimer
            showModal={selectPhone}
            setShowModal={() => setSelectPhone(false)}
            body={<FormattedMessage id="select_one" />}
            confirmText={<FormattedMessage id="ok" />}
            handleClick={() => setSelectPhone(false)}
          />
        </div>
      </div>
      <ContractAmountDetailsModal
        visible={confirmChargesModal}
        title={<FormattedMessage id="CONTRACT_AMOUNT_DETAIL" />}
        contractId={null}
        gigmiCharges={gigmiCharges}
        flatAmount={formValues?.flat_amount}
        cancelText={<FormattedMessage id="CANCEL" />}
        cancelAction={() => setConfirmChargesModal(false)}
        confirmText={<FormattedMessage id="CONFIRM" />}
        confirmAction={() => {
          setConfirmChargesModal(false);
          QuickContract(formValues);
        }}
      />
      <Modal
        open={userListModal}
        onCancel={() => {
          setUserListModal(false);
        }}
        footer={null}
        className="p-3"
      >
        <div className="mt-3">
          <div className="date-div fw-bold fs-5">
            <FormattedMessage id="select_profile" />
          </div>
          <UserList
            SortedUsers={userList}
            selectedTradesman={selectedUser}
            path={path}
            handleSelectUser={(item) => {
              setSelectedUserdata(item);
              dispatch(setSelectedUser([item?.viewed_user_type_id]));
            }}
          />
          <div className="date-div ">
            <Button
              className="w-100 fw-bold mx-1 acceptContractBtnWhite"
              onClick={() => {
                dispatch(setSelectedUser());
                setUserListModal(false);
              }}
            >
              <FormattedMessage id="cancel" />
            </Button>
            <Button
              className="w-100 fw-bold mx-1 acceptContractBtn"
              onClick={() => {
                if (selectedUserdata?.username?.includes("@")) {
                  setHideshowEmail("email");
                } else {
                  setHideshowEmail("phone");
                }
                form?.setFieldValue("username", selectedUserdata?.username);
                setUserListModal(false);
              }}
            >
              <FormattedMessage id="Select" />
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default injectIntl(SendEstimatiionswithOptions);
