import { Axios } from "../../utils/axiosMiddleware";
import { SimpleCatchError } from "../../utils/Helper";
export const PaymentScreenOneSubmit = async (body) => {
  try {
    const response = await Axios.post(`gigwage/receiving-account`, body);
    return response;
  } catch (e) {
    SimpleCatchError(e);
  }
};

export const changeGigwageEmail = async (body, id, navigate) => {
  try {
    await Axios.patch(`gigwage/change-email/${id}`, body);
    navigate("/");
  } catch (e) {
    SimpleCatchError(e);
  }
};
