import axios from "axios";
import React, { useState } from "react";
import { base_url } from "../../constants";
import { Button, message } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import lodash, { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import Loader from "../GeneralComponents";
import { injectIntl } from "react-intl";
import { TextOverImageModal } from "../Stories/TextOverImage";
const FourthPage = ({ current, setCurrent, ...props }) => {
  const dataUser = useSelector((state) => state?.common?.fullUser);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(
    dataUser?.current_type === "contractor"
      ? !isEmpty(dataUser?.contractor?.contractor_files) &&
          dataUser?.contractor?.contractor_files?.filter(
            (item) => item.type === "profile"
          )
      : dataUser?.current_type === "tradesman"
      ? !isEmpty(dataUser?.tradesman?.tradesman_files) &&
        dataUser?.tradesman?.tradesman_files?.filter(
          (item) => item.type === "profile"
        )
      : ""
  );
  const [loading, setLoading] = useState(false);
  const [newArray, setNewArray] = useState([]);
  const handleSubmit = async (event) => {
    const file = event.target.files[0];
    event.target.value = "";
    const reader = new FileReader();
    reader.onload = (e) => {
      setData((prev) => [
        ...prev,
        { key: e.target.result, file: file, text: null },
      ]);
    };
    reader.readAsDataURL(file);
    setOpen(true);
  };
  const handleClearClick = (index) => {
    if (data[index]?.type) {
      setNewArray((prev) => [...prev, data[index]?.id]);
    }
    let pics = [...data];
    pics.splice(index, 1);
    setData(pics);
  };

  const handleSubmitForm = async () => {
    if (data.length == 0) {
      message.error(`${props.intl.formatMessage({ id: "upload_atleast" })}`);
      return;
    }
    const accessToken = JSON.parse(localStorage.getItem("data"));
    const formData = new FormData();
    data.map((item) => {
      if (!item?.id) {
        formData.append("files", item?.file);
      }
    });
    const textLayout = data
      ?.map((item) => {
        if (!item?.id) {
          return item?.text;
        }
      })
      .filter((item) => item !== undefined);
    formData.append("deletedFileId", JSON.stringify(newArray));
    formData.append("textOverLay", textLayout);
    formData.append(
      accessToken?.user_type === "tradesman" ? "tradesmanId" : "contractorId",
      accessToken?.id
    );
    formData.append("userRole", accessToken?.user_type);
    formData.append("type", "profile");
    try {
      setLoading(true);
      await axios
        .post(`${base_url}user/profile`, formData, {
          headers: {
            Authorization: `Bearer ${accessToken?.accessToken}`,
            "ngrok-skip-browser-warning": true,
          },
        })
        .then((response) => {
          message.success(
            `${props.intl.formatMessage({ id: "profile_updated" })}`
          );
          setLoading(false);
          navigate("/");
        })
        .catch((error) => {
          setLoading(false);
          const errorMessage = error.response.data.message;
          message.error(errorMessage);
        });
    } catch (error) {
      message.error(
        `${props.intl.formatMessage({ id: "something_went_wrong" })}`
      );
    }
    setLoading(false);
  };
  const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  return (
    <div>
      {loading && (
        <div className="search-loader">
          <Loader />
        </div>
      )}

      <div className="m-1 row shadow p-0  p-sm-1 p-md-3 p-lg-5 rounded ">
        {arr.map((item, index) => {
          return (
            <div className="col-4 div-image-col ">
              <div className="imageUpload  ">
                <input
                  id={`media`}
                  type="file"
                  accept="image/*"
                  onChange={handleSubmit}
                  className="imageUpload-input"
                />

                <div className="preview">
                  {!data[index] && (
                    <label htmlFor={`media`}>
                      <div
                        style={{
                          width: "30px",
                          height: "30px",
                          backgroundColor: "#F77E0B",
                          fontSize: "30px",
                          color: "white",
                          cursor: "pointer",
                        }}
                        className="d-flex justify-content-center align-items-center rounded-circle"
                      >
                        <span className="mb-1">+</span>
                      </div>
                    </label>
                  )}
                </div>
                {data[index] && (
                  <div className="preview">
                    <img
                      src={
                        data[index]?.type
                          ? `${process.env.REACT_APP_S3_BUCKET_URL}${data[index]?.key}`
                          : `${data[index]?.key}`
                      }
                      className=" w-100 h-100 rounded"
                      style={{ objectFit: "cover" }}
                    />
                    <span
                      className="close-icon"
                      onClick={() => handleClearClick(index)}
                    >
                      <CloseOutlined />
                    </span>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className="d-flex justify-content-between mt-5">
        <div>
          {current > 0 && (
            <Button
              style={{
                width: "120px",
                height: "56px",
                color: "#F77E0B",
                border: "2px solid #F77E0B",
              }}
              onClick={() => setCurrent((prevCurrent) => prevCurrent - 1)}
            >
              {props.intl.formatMessage({ id: "previous" })}
            </Button>
          )}
        </div>
        <div>
          {current < 5 && (
            <Button
              style={{
                width: "120px",
                backgroundColor: "#F77E0B",
                height: "56px",
                color: "white",
              }}
              type="submit"
              htmlType="submit"
              onClick={handleSubmitForm}
            >
              {props.intl.formatMessage({ id: "update" })}
            </Button>
          )}
        </div>
      </div>
      <TextOverImageModal
        open={open}
        setOpen={setOpen}
        fromProfile={true}
        data={data}
        setData={setData}
      />
    </div>
  );
};

export default injectIntl(FourthPage);
