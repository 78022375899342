import { Suspense, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";
import Loader from "./components/GeneralComponents";
import Otp from "./components/Otp/Otp";
import ProfileSelection from "./components/ProfileSelection/ProfileSelection";
import SignIn from "./components/SignIn/SignIn";
import SignUpModal from "./components/SignUp/SignUp";
import TradProfile from "./components/TradsmanProfile/TradsmanProfile";
import UpdateProfile from "./components/UpdateProfile/UpdateProfile";
import UserProfile from "./components/UserProfile/UserProfile";
import Welcome from "./components/Welcome/Welcome";
import Home from "./views/Home/Home";
import { getUserDetails } from "./views/Home/Service";
import PrivacyPolicy from "./views/PrivacyPolicy/PrivacyPolicy";
import SoftwareLicense from "./views/SoftwareLicense/SoftwareLicense";
import TermsOfServices from "./views/TermsOfServices/TermsOfServices";
import EnglishMessages from "./Language/en.json";
import SpanishMessages from "./Language/espanol.json";
import { IntlProvider } from "react-intl";
import PaymentScreenOne from "./views/bankInfoPages/paymentScreenOne";
import PaymentScreenTwo from "./views/bankInfoPages/paymentScreenTwo";
import PaymentScreenThree from "./views/bankInfoPages/paymentScreenThree";
import BankAccountList from "./views/bankInfoPages/bankAccountList";
import PaymentOptions from "./views/bankInfoPages/paymentOptions";
import AddHomAccount from "./views/bankInfoPages/addHomAccount";
import EarningSpendingInfo from "./views/earningSpendingInfoPage/earningSpendingInfo";
import Wallet from "./views/wallet/wallet";
import Settings from "./views/settings/Settings";
import ShareApp from "./views/ShareApp/ShareApp.jsx";
import Chat from "./views/chat/Chat";
import DepositInstructions from "./views/DepositInstructions/DepositInstructions";
import { W9from } from "./views/W9Form/W9from";
import FeeAgreement from "./views/FeeAgreement/FeeAgreement";
import ContractListing from "./views/Contracts/ContractListing.js";
import ContractDetails from "./views/Contracts/contractDetails.js";
import SubmitReview from "./views/SubmitReview/SubmitReview";
import { CreateContractScreen } from "./views/Contracts/createContract.js";
import SupportPage from "./views/Support/SupportPage.jsx";
import GenerateReceiptsAndInvoicesScreen from "./views/ReceiptsAndInvoicesScreen/GenerateReceiptsAndInvoicesScreen.js";
import CreateInvoiceAndReceiptScreen from "./views/ReceiptsAndInvoicesScreen/CreateInvoiceAndReceiptScreen.js";
import SendEstimationswithOptions from "./views/Contracts/SendEstimationswithOptions.js";
import { SocketConnection } from "./sockets/SocketConnection";
import { messaging } from "./utils/firebase.js";
import { onMessage } from "firebase/messaging";
import { message } from "antd";
import { FeedsListing } from "./views/Feeds/FeedsListing.js";
import CreatePost from "./views/Feeds/CreatePost.js";
import { PostDetails } from "./views/Feeds/PostDetails.js";
import SendOffer from "./views/Feeds/SendOffer.js";
import { HowToUseApp } from "./views/HowToUseApp/HowToUseApp.js";
import { Map } from "./views/Maps/Map.js";
import { UploadStories } from "./components/Stories/UploadStories";
import UploadProfileVideos from "./views/Profile/UploadProfileVideos.js";
import { Subscription } from "./views/Subscription/Subscription.js";
import ChangeUsername from "./views/settings/ChangeUsername.js";
import { OtpVerification } from "./views/settings/OtpVerification.js";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      onMessage(messaging, (payload) => {
        message.success(payload.notification.body);
      });
      await getUserDetails(dispatch);
    };
    fetchData();
  }, []);

  const lang = JSON.parse(localStorage.getItem("lang"));

  let Language;

  if (lang) {
    Language = "es";
  } else {
    Language = "en";
  }

  const connectSockets = async () => {
    const isAuthenticated = localStorage.getItem("data");
    if (isAuthenticated) {
      const token = JSON.parse(isAuthenticated);
      const accessToken = token?.accessToken;
      await SocketConnection(accessToken);
    }
  };

  useEffect(() => {
    connectSockets();
  }, []);

  const messages = {
    es: SpanishMessages,
    en: EnglishMessages,
  };
  const isAuthenticated = localStorage.getItem("data");
  return (
    <IntlProvider
      locale={Language}
      messages={messages[Language]}
      defaultLocale="en"
    >
      <Suspense fallback={<Loader />}>
        <>
          <Routes>
            <Route path="/login" element={<SignIn />} />
            <Route path="/signup" element={<SignUpModal />} />
            <Route path="/profile-selection" element={<ProfileSelection />} />
            <Route path="/verifyOtp" element={<Otp />} />
            <Route path={`/privacy-policy/:lang`} element={<PrivacyPolicy />} />
            <Route
              path={`/deposit-instructions/:lang`}
              element={<DepositInstructions />}
            />

            <Route
              path="/terms-of-services/:lang"
              element={<TermsOfServices />}
            />
            <Route
              path="/software-license/:lang"
              element={<SoftwareLicense />}
            />
            <Route path="/fee-agreement/:lang" element={<FeeAgreement />} />
            <Route path="/" element={<Home />} />
            <Route path="/welcome" element={<Welcome />} />
            <Route path="/support/:lang" element={<SupportPage />} />
            <Route path="subscription" element={<Subscription />} />
            <Route path="how-use-app" element={<HowToUseApp />} />
            {isAuthenticated && (
              <>
                <Route path="/submit-review" element={<SubmitReview />} />
                <Route path={`/trads-profile`} element={<TradProfile />} />
                <Route path={`/share-app`} element={<ShareApp />} />
                <Route
                  path={`/generate-invoices-or-receipts`}
                  element={<GenerateReceiptsAndInvoicesScreen />}
                />
                <Route
                  path={`/create-invoices-or-receipts`}
                  element={<CreateInvoiceAndReceiptScreen />}
                />
                <Route path="/user-profile" element={<UserProfile />} />
                <Route path="/update-user" element={<UpdateProfile />} />
                <Route path="/get-payments" element={<PaymentScreenOne />} />
                <Route path="/w9-form" element={<PaymentScreenTwo />} />
                <Route path="/bank-info" element={<PaymentScreenThree />} />
                <Route path="/bank-accounts" element={<BankAccountList />} />
                <Route path="/payment-options" element={<PaymentOptions />} />
                <Route path="/add-hom-account" element={<AddHomAccount />} />
                <Route
                  path="/spendings-earnings"
                  element={<EarningSpendingInfo />}
                />
                <Route path="/wallet" element={<Wallet />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/chat" element={<Chat />} />
                <Route path="/view-w9-form" element={<W9from />} />
                <Route path="/contract-lists" element={<ContractListing />} />
                <Route path="/contract-details" element={<ContractDetails />} />
                <Route
                  path="/create-contract"
                  element={<CreateContractScreen />}
                />
                <Route
                  path="/send-estimations"
                  element={<SendEstimationswithOptions />}
                />
                <Route path="feeds" element={<FeedsListing />} />
                <Route path="create-post" element={<CreatePost />} />
                <Route path="post-details" element={<PostDetails />} />
                <Route path="send-offer" element={<SendOffer />} />
                <Route path="gigmi-maps" element={<Map />} />
                <Route path="upload-gigs" element={<UploadStories />} />
                <Route path="upload-videos" element={<UploadProfileVideos />} />
                <Route path="/change-username" element={<ChangeUsername />} />
                <Route path="/otp-verification" element={<OtpVerification />} />
              </>
            )}
          </Routes>
        </>
      </Suspense>
    </IntlProvider>
  );
}

export default App;
