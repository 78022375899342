import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import image from "../../assets/images/imagepic.jpeg";
import { getCurrentUserDetails, getFullUser } from "../../views/Home/Service";
import { Progress } from "antd";
import profileIcon from "../../assets/images/profileIcon.png";
import thumbNail from "../../assets/images/video.png";
import { EnvironmentOutlined } from "@ant-design/icons";
import ImageViewModal from "./ImageViewModal";
import Header from "../Header/Header";
import { injectIntl } from "react-intl";
import playIcon from "../../assets/images/playvideo.png";
import AddIconColored from "../../assets/images/Edit.png";

import "./style.css";
import { isEmpty } from "lodash";
import { bucket_base_url } from "../../constants";
import { useNavigate } from "react-router";
import VideoPlayModal from "./VideoPlayModal";

function UserProfile(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginUser = JSON.parse(localStorage.getItem("data"));
  const userData = useSelector((state) => state?.common?.fullUser);
  const currentUser = useSelector((state) => state?.common?.newUser?.data);
  const profileVideos =
    loginUser?.user_type === "tradesman"
      ? userData?.tradesman?.tradesman_files
      : userData?.contractor?.contractor_files;
  const videos =
    !isEmpty(profileVideos) &&
    profileVideos?.filter((item) => item?.type === "video");
  const pictures =
    !isEmpty(profileVideos) &&
    profileVideos?.filter(
      (item) => item?.type === "image" || item?.type === "profile"
    );
  useEffect(() => {
    getFullUser(dispatch);
    getCurrentUserDetails(dispatch);
  }, []);

  const accessToken = JSON.parse(localStorage.getItem("data"));
  const [currentIndex, setCurrentIndex] = useState("");
  const [videoCurrentIndex, setVideoCurrentIndex] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const handleImageSelect = (index) => {
    setCurrentIndex(index);
    setShowModal(true);
  };
  const handleVideoSelect = (index) => {
    setVideoCurrentIndex(index);
    setShowVideoModal(true);
  };

  return (
    <>
      <Header />
      {userData && (
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12 col-12 d-flex flex-column align-items-center mt-5 mb-2">
              <span className="profile-heading">
                {props.intl.formatMessage({ id: "header.profile" })}
              </span>
              <div
                style={{ position: "relative", height: "150px" }}
                className="d-flex justify-content-center"
              >
                <Progress
                  type="circle"
                  percent={currentUser?.health}
                  strokeColor="#F77E0B"
                  showInfo={false}
                  style={{ position: "absolute" }}
                />
                <div
                  style={{
                    width: "105px",
                    height: "105px",
                    marginTop: "7px",
                  }}
                >
                  {userData && accessToken.user_type === "contractor" && (
                    <img
                      src={
                        userData?.contractor?.profile_picture === null ||
                        userData?.contractor?.profile_picture === ""
                          ? profileIcon
                          : `${process.env.REACT_APP_S3_BUCKET_URL}${userData?.contractor?.profile_picture}`
                      }
                      className="img-fluid rounded-circle"
                    />
                  )}
                  {userData && accessToken.user_type === "tradesman" && (
                    <img
                      src={
                        userData?.tradesman?.profile_picture === null ||
                        userData?.tradesman?.profile_picture === ""
                          ? profileIcon
                          : `${process.env.REACT_APP_S3_BUCKET_URL}${userData?.tradesman?.profile_picture}`
                      }
                      className="img-fluid rounded-circle"
                    />
                  )}
                  {userData && accessToken.user_type === "homeowner" && (
                    <img
                      src={
                        userData?.homeowner?.profile_picture === null ||
                        userData?.homeowner?.profile_picture === ""
                          ? profileIcon
                          : `${process.env.REACT_APP_S3_BUCKET_URL}${userData?.homeowner?.profile_picture}`
                      }
                      className="img-fluid rounded-circle"
                    />
                  )}
                </div>
                <div
                  style={{
                    position: "absolute",
                    marginTop: "80px",
                    marginLeft: "180px",
                  }}
                >
                  <span className="health-show">{currentUser?.health}%</span>
                </div>
              </div>

              {userData && accessToken.user_type === "contractor" && (
                <span className="profile-username text-center">
                  {userData?.contractor?.first_name}{" "}
                  {userData?.contractor?.last_name}
                </span>
              )}
              {userData && accessToken.user_type === "tradesman" && (
                <span className="profile-username text-center">
                  {userData?.tradesman?.first_name}{" "}
                  {userData?.tradesman?.last_name}
                </span>
              )}
              {userData && accessToken.user_type === "homeowner" && (
                <span className="profile-username text-center">
                  {userData?.homeowner?.first_name}{" "}
                  {userData?.homeowner?.last_name}
                </span>
              )}

              <div className="shadow rounded profile-details-div  ">
                <span className="trad-username" style={{ fontWeight: "600" }}>
                  {props.intl.formatMessage({ id: "username" })}
                </span>
                <span
                  className="trad-username w-75 ms-1  "
                  style={{ wordWrap: "break-word" }}
                >
                  {userData?.username}
                </span>
              </div>
              {userData?.email && (
                <div className="shadow rounded profile-details-div">
                  <span className="trad-username" style={{ fontWeight: "600" }}>
                    {props.intl.formatMessage({ id: "email" })}
                  </span>
                  <span
                    className="trad-username ms-1"
                    style={{ wordWrap: "break-word" }}
                  >
                    {userData?.email}
                  </span>
                </div>
              )}
              {userData?.phone && (
                <div className="shadow rounded profile-details-div">
                  <span className="trad-username" style={{ fontWeight: "600" }}>
                    {props.intl.formatMessage({ id: "phone" })}
                  </span>
                  <span
                    className="trad-username ms-1"
                    style={{ wordWrap: "break-word" }}
                  >
                    {userData?.phone}
                  </span>
                </div>
              )}
              {userData?.city && userData?.state && (
                <span className="mt-3">
                  <EnvironmentOutlined style={{ color: "#F77E0B" }} />
                  <span className="profile-address">
                    {userData?.city} ,{userData?.state}
                  </span>
                </span>
              )}
            </div>
            <div className="col-md-6 col-sm-12 col-12 d-flex flex-column align-items-sm-center align-items-md-start align-items-center mt-none mt-sm-none mt-md-4">
              {userData?.current_type === "contractor" &&
                userData?.contractor?.logo && (
                  <div className="mt-4 w-100">
                    <span className="profile-heading">
                      {props.intl.formatMessage({ id: "companylogo" })}
                    </span>
                    <div className="profile-image rounded-circle d-flex justify-content-center w-100">
                      <img
                        src={`${process.env.REACT_APP_S3_BUCKET_URL}${userData?.contractor?.logo}`}
                        alt=""
                        className="rounded-circle"
                        style={{
                          objectFit: "cover",
                          width: "100px",
                          height: "100px",
                        }}
                      />
                    </div>
                  </div>
                )}

              <div className="w-100">
                {accessToken.user_type !== "homeowner" && (
                  <>
                    <span className="profile-subheading">
                      {props.intl.formatMessage({ id: "otherpicture" })}
                    </span>
                    <div
                      className="profile-pictures shadow d-flex align-items-center "
                      style={{ height: "150px" }}
                    >
                      <div
                        style={{
                          overflowX: "scroll",
                          display: "flex",
                          width: "280px",
                        }}
                      >
                        {!isEmpty(pictures) ? (
                          pictures?.map((image, index) => {
                            return (
                              <div key={index} className=" ms-1 rounded">
                                <img
                                  src={`${process.env.REACT_APP_S3_BUCKET_URL}${image.key}`}
                                  alt=""
                                  className=" rounded"
                                  style={{
                                    width: "60px",
                                    height: "60px",
                                    objectFit: "cover",
                                  }}
                                  onClick={() => handleImageSelect(index)}
                                />
                              </div>
                            );
                          })
                        ) : (
                          <div
                            className="w-100 d-flex justify-content-center"
                            style={{ fontFamily: "Poppins" }}
                          >
                            <span className="text-center">
                              {props.intl.formatMessage({ id: "noimages" })}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {accessToken.user_type !== "homeowner" && (
                  <>
                    <span className="profile-subheading">
                      {props.intl.formatMessage({ id: "skills" })}
                    </span>
                    <div className="profile-pictures shadow d-flex flex-wrap">
                      {accessToken.user_type === "contractor" ? (
                        userData?.contractor?.skills.length > 0 &&
                        userData?.contractor?.skills.map((skill) => {
                          return (
                            <div className="skill-skill mt-2" key={skill?.id}>
                              <span>{skill.user_skill}</span>
                            </div>
                          );
                        })
                      ) : accessToken.user_type === "tradesman" ? (
                        userData?.tradesman?.skills.length > 0 &&
                        userData?.tradesman?.skills.map((skill) => {
                          return (
                            <div className="skill-skill mt-2" key={skill?.id}>
                              <span>{skill.user_skill}</span>
                            </div>
                          );
                        })
                      ) : (
                        <></>
                      )}
                      {accessToken.user_type === "contractor" ? (
                        userData?.contractor?.skills.length == 0 && (
                          <div
                            className="w-100 d-flex justify-content-center align-items-center"
                            style={{ fontFamily: "Poppins" }}
                          >
                            <span className="text-center mt-3">
                              {props.intl.formatMessage({ id: "noskills" })}
                            </span>
                          </div>
                        )
                      ) : accessToken.user_type === "tradesman" ? (
                        userData?.tradesman?.skills.length == 0 && (
                          <div
                            className="w-100 d-flex justify-content-center align-items-center"
                            style={{ fontFamily: "Poppins" }}
                          >
                            <span className="text-center mt-3">
                              {props.intl.formatMessage({ id: "noskills" })}
                            </span>
                          </div>
                        )
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                )}
                {accessToken.user_type !== "homeowner" && (
                  <>
                    <span className="profile-subheading">
                      {props.intl.formatMessage({ id: "introtext" })}
                    </span>
                    <div className="profile-intro shadow p-4">
                      {accessToken.user_type === "contractor" && (
                        <div style={{ overflowY: "scroll", height: "100%" }}>
                          <span style={{ wordWrap: "break-word" }}>
                            {userData?.contractor?.intro_text}
                          </span>
                        </div>
                      )}
                      {accessToken.user_type === "tradesman" && (
                        <div style={{ overflowY: "scroll", height: "100%" }}>
                          <span style={{ wordWrap: "break-word" }}>
                            {userData?.tradesman?.intro_text}
                          </span>
                        </div>
                      )}
                      {accessToken.user_type === "contractor" ? (
                        !userData?.contractor?.intro_text && (
                          <div
                            className="w-100 d-flex justify-content-center align-items-center"
                            style={{ fontFamily: "Poppins" }}
                          >
                            <span className="text-center mt-1">
                              {props.intl.formatMessage({ id: "nointro" })}
                            </span>
                          </div>
                        )
                      ) : accessToken.user_type === "tradesman" ? (
                        !userData?.tradesman?.intro_text && (
                          <div
                            className="w-100 d-flex justify-content-center align-items-center"
                            style={{ fontFamily: "Poppins" }}
                          >
                            <span className="text-center mt-1">
                              {props.intl.formatMessage({ id: "nointro" })}
                            </span>
                          </div>
                        )
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                )}
                {accessToken?.user_type !== "homeowner" && (
                  <>
                    <div className="d-flex justify-content-between">
                      <span className="profile-subheading">
                        {props.intl.formatMessage({ id: "videos" })}
                      </span>
                      <img
                        src={AddIconColored}
                        alt=""
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate(`/upload-videos`, {
                            state: { ProfileData: userData },
                          })
                        }
                      />
                    </div>
                    <div className=" profile-videos-div mt-3 shadow">
                      {!isEmpty(videos) &&
                        videos.map((item, index) => {
                          return (
                            <div
                              className="profile-video-card border shadow"
                              key={item?.id}
                            >
                              <img
                                className="play-icon"
                                src={playIcon}
                                alt=""
                              />

                              <img
                                src={
                                  item?.thumbnail
                                    ? `${bucket_base_url}${item?.thumbnail}`
                                    : thumbNail
                                }
                                alt=""
                                className="w-100 h-100"
                                style={{
                                  objectFit: "cover",
                                  borderRadius: "10px",
                                }}
                                onClick={() => handleVideoSelect(index)}
                              />
                            </div>
                          );
                        })}
                      {isEmpty(videos) && (
                        <div
                          className="w-100 d-flex justify-content-center"
                          style={{ fontFamily: "Poppins" }}
                        >
                          <span className="text-center">
                            {props.intl.formatMessage({ id: "novideos" })}
                          </span>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {userData && showModal && (
        <ImageViewModal
          images={pictures}
          index={currentIndex}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
      {userData && showVideoModal && (
        <VideoPlayModal
          images={videos}
          index={videoCurrentIndex}
          showModal={showVideoModal}
          setShowModal={setShowVideoModal}
        />
      )}
    </>
  );
}

export default injectIntl(UserProfile);
