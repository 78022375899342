import axios from "axios";
import { base_url } from "../constants";
const token = JSON.parse(localStorage.getItem("data"));
export const formAxios = axios.create({
  baseURL: base_url,
  headers: {
    ContentType: "multipart/form-data",
    Authorization: `Bearer ${token?.accessToken}`,
  },
});

export const Axios = axios.create({
  baseURL: base_url,
  headers: {
    Authorization: `Bearer ${token?.accessToken}`,
    "ngrok-skip-browser-warning": true,
  },
});
