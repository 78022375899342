import { Button, Checkbox, Form, Input, Modal, Select } from "antd";
import React, { useRef, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import Header from "../../components/Header/Header";
import SignatureCanvas from "react-signature-canvas";
import { useSelector } from "react-redux";
import { CustomerCreation } from "../../api/bankApi/AddBankAccount";
import Loader from "../../components/GeneralComponents";
import { BackButton } from "../../components/General/BackButton";
import usCitiesWithCode from "../../assets/CitiesWithStateCodes";
import usStates1 from "../../assets/UsStates";
const AddHomAccount = (props) => {
  const searchParams = new URLSearchParams(window.location.search);
  const path = searchParams.get("path");
  const [form] = Form.useForm();
  const signatureRef = useRef();
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [loading, setLoading] = useState(false);
  const [electronicFundTransfer, setElectronicFundTransfer] = useState(false);
  const [transferAgreement, setTransferAgreement] = useState(false);
  const [signature, setSignature] = useState(null);
  const [signatureModal, setSignatureModal] = useState(false);
  const userTypeData = useSelector((state) => state?.common?.fullUser);

  const handleSubmit = async (values) => {
    if (electronicFundTransfer && transferAgreement && signature) {
      const body = {
        name: values.name,
        email: values.email?.trim()?.toLowerCase(),
        phone_number: values.phoneNumber.replace(/\D/g, ""),
        address1: values.address,
        state: values.state,
        zip: values.zip.replace(/\D/g, ""),
        city: values.city,
        account_number: values.accountNumber,
        routing_number: values.routingNumber,
        account_type: values.accountType,
        financial_institution_name: values.FIName,
        signature_file: signature,
        userId: userTypeData?.id,
      };
      setLoading(true);
      await CustomerCreation(body, path);
      setLoading(false);
    } else {
      setSignatureModal(true);
    }
  };
  const handleSaveSignature = () => {
    const signatureDataURL = signatureRef.current.toDataURL();
    const base64Signature = signatureDataURL.split(",")[1];
    setSignature(base64Signature);
    if (base64Signature) {
      setSignatureModal(false);
      handleSubmit();
    }
  };
  const handleClearSignature = () => {
    signatureRef.current.clear();
  };
  const PaymentAccountType = [
    { label: "Checking", value: "checking" },
    { label: "Savings", value: "savings" },
  ];
  const Cities = [];
  usCitiesWithCode.map((city) => {
    if (city.state === state) {
      Cities.push({
        label: city.city,
        value: city.city,
      });
    }
  });
  const handleState = (e) => {
    setCity("");
    form.setFieldValue({ city: city });
    form.resetFields(["city"]);
    setState(e);
  };

  return (
    <>
      {loading && (
        <div className="search-loader">
          <Loader />
        </div>
      )}

      <BackButton />
      <div className="container paymentscreen">
        <Header />
        <div className="paymentscreen-inner">
          <div className="d-flex justify-content-center align-items-center getting-payment-heading shadow border text-center">
            <span>
              <FormattedMessage id="add_bank_info" />
            </span>
          </div>
          <Form
            onFinish={handleSubmit}
            form={form}
            layout="vertical"
            initialValues={{
              state: "",
              city: "",
              address: "",
              phoneNumber: "",
              email: "",
              confirmEmail: "",
              FIName: "",
              name: "",
              zip: "",
              accountNumber: "",
              confirmAccountNumber: "",
              routingNumber: "",
              confirmRoutingNumber: "",
              accountType: "",
            }}
          >
            <Form.Item
              name="name"
              label={props.intl.formatMessage({ id: "account_name" })}
              rules={[
                {
                  required: true,
                  message: `${props.intl.formatMessage({ id: "required" })}`,
                },
              ]}
            >
              <Input
                placeholder={props.intl.formatMessage({ id: "account_name" })}
                className="input-field"
              />
            </Form.Item>

            <Form.Item
              name="FIName"
              label={props.intl.formatMessage({
                id: "financial_institute_name",
              })}
              rules={[
                {
                  required: true,
                  message: `${props.intl.formatMessage({ id: "required" })}`,
                },
              ]}
            >
              <Input
                placeholder={props.intl.formatMessage({
                  id: "financial_institute_name",
                })}
                className="input-field"
              />
            </Form.Item>
            <Form.Item
              name="email"
              label={props.intl.formatMessage({ id: "email" })}
              rules={[
                {
                  required: true,
                  message: `${props.intl.formatMessage({ id: "required" })}`,
                },
              ]}
            >
              <Input
                type="email"
                placeholder={props.intl.formatMessage({ id: "email" })}
                className="input-field"
              />
            </Form.Item>
            <Form.Item
              name="confirmEmail"
              label={props.intl.formatMessage({ id: "confirm_email" })}
              rules={[
                {
                  required: true,
                  message: `${props.intl.formatMessage({ id: "required" })}`,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("email") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      `${props.intl.formatMessage({
                        id: "email_do_not_match",
                      })}`
                    );
                  },
                }),
              ]}
            >
              <Input
                type="email"
                placeholder={props.intl.formatMessage({ id: "confirm_email" })}
                className="input-field"
              />
            </Form.Item>
            <Form.Item
              name="phoneNumber"
              label={props.intl.formatMessage({ id: "phone_number" })}
              rules={[
                {
                  required: true,
                  message: `${props.intl.formatMessage({ id: "required" })}`,
                },
                {
                  pattern: new RegExp(/^[0-9]*(?:\.[0-9]*)?$/),
                  message: `${props.intl.formatMessage({
                    id: "only_numeric",
                  })}`,
                },
              ]}
            >
              <Input
                placeholder={props.intl.formatMessage({ id: "phone_number" })}
                className="input-field"
              />
            </Form.Item>
            <Form.Item
              name="address"
              label={props.intl.formatMessage({ id: "address" })}
              rules={[
                {
                  required: true,
                  message: `${props.intl.formatMessage({ id: "required" })}`,
                },
              ]}
            >
              <Input
                placeholder={props.intl.formatMessage({ id: "address" })}
                type="text"
                className="input-field"
              />
            </Form.Item>
            <div className="row">
              <div className="col-6">
                <Form.Item
                  name="state"
                  label={props.intl.formatMessage({ id: "state" })}
                  rules={[
                    {
                      required: true,
                      message: `${props.intl.formatMessage({
                        id: "state_required",
                      })}`,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={{
                      width: "100%",
                    }}
                    placeholder={props.intl.formatMessage({ id: "state" })}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={usStates1}
                    onSelect={(e) => handleState(e)}
                  />
                </Form.Item>
              </div>
              <div className="col-6">
                <Form.Item
                  name="city"
                  label={props.intl.formatMessage({ id: "city" })}
                  rules={[
                    {
                      required: true,
                      message: `${props.intl.formatMessage({
                        id: "city_required",
                      })}`,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={{
                      width: "100%",
                    }}
                    placeholder={props.intl.formatMessage({ id: "state" })}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={Cities}
                  />
                </Form.Item>
              </div>
            </div>
            <Form.Item
              name="zip"
              label={props.intl.formatMessage({ id: "zip" })}
              rules={[
                {
                  required: true,
                  message: `${props.intl.formatMessage({ id: "required" })}`,
                },
                {
                  pattern: new RegExp(/^[0-9]*(?:\.[0-9]*)?$/),
                  message: `${props.intl.formatMessage({
                    id: "only_numeric",
                  })}`,
                },
              ]}
            >
              <Input
                placeholder={props.intl.formatMessage({ id: "zip" })}
                className="input-field"
              />
            </Form.Item>

            <Form.Item
              name="accountNumber"
              label={props.intl.formatMessage({ id: "account_number" })}
              rules={[
                {
                  required: true,
                  message: `${props.intl.formatMessage({ id: "required" })}`,
                },
                {
                  pattern: new RegExp(/^[0-9]*(?:\.[0-9]*)?$/),
                  message: `${props.intl.formatMessage({
                    id: "only_numeric",
                  })}`,
                },
              ]}
            >
              <Input
                placeholder={props.intl.formatMessage({ id: "account_number" })}
                className="input-field"
              />
            </Form.Item>

            <Form.Item
              name="confirmAccountNumber"
              label={props.intl.formatMessage({ id: "confirm_account_number" })}
              rules={[
                {
                  required: true,
                  message: `${props.intl.formatMessage({ id: "required" })}`,
                },
                {
                  pattern: new RegExp(/^[0-9]*(?:\.[0-9]*)?$/),
                  message: `${props.intl.formatMessage({
                    id: "only_numeric",
                  })}`,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("accountNumber") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      `${props.intl.formatMessage({
                        id: "account_do_not_match",
                      })}`
                    );
                  },
                }),
              ]}
            >
              <Input
                className="input-field"
                placeholder={props.intl.formatMessage({
                  id: "confirm_account_number",
                })}
              />
            </Form.Item>
            <Form.Item
              name="routingNumber"
              label={props.intl.formatMessage({ id: "routing_number" })}
              rules={[
                {
                  required: true,
                  message: `${props.intl.formatMessage({ id: "required" })}`,
                },
                {
                  pattern: new RegExp(/^[0-9]*(?:\.[0-9]*)?$/),
                  message: `${props.intl.formatMessage({
                    id: "only_numeric",
                  })}`,
                },
              ]}
            >
              <Input
                placeholder={props.intl.formatMessage({ id: "routing_number" })}
                maxLength={9}
                className="input-field"
              />
            </Form.Item>

            <Form.Item
              name="confirmRoutingNumber"
              label={props.intl.formatMessage({ id: "confirm_routing_number" })}
              rules={[
                {
                  required: true,
                  message: `${props.intl.formatMessage({ id: "required" })}`,
                },
                {
                  pattern: new RegExp(/^[0-9]*(?:\.[0-9]*)?$/),
                  message: `${props.intl.formatMessage({
                    id: "only_numeric",
                  })}`,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("routingNumber") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      `${props.intl.formatMessage({
                        id: "routing_number_do_not_match",
                      })}`
                    );
                  },
                }),
              ]}
            >
              <Input
                className="input-field"
                maxLength={9}
                placeholder={props.intl.formatMessage({
                  id: "confirm_routing_number",
                })}
              />
            </Form.Item>

            <Form.Item
              name="accountType"
              label={props.intl.formatMessage({ id: "account_type" })}
              rules={[
                {
                  required: true,
                  message: `${props.intl.formatMessage({ id: "required" })}`,
                },
              ]}
            >
              <Select
                placeholder={props.intl.formatMessage({ id: "account_type" })}
                options={PaymentAccountType}
              />
            </Form.Item>
            <div className="mt-5">
              <span>
                {props.intl.formatMessage({
                  id: "GIGWAGE_CONTRACTOR_ACCOUNT_DISCLAIMER",
                })}
              </span>
            </div>
            <div className="mt-3">
              <span className="fw-bold">
                {props.intl.formatMessage({
                  id: "CUSTOMER_CREATION_TERMS_AND_CONDITIONS_AGREEMENT_MESSAGE",
                })}
              </span>
            </div>
            <div className="d-flex mt-5">
              <Form.Item
                name="term1"
                rules={[
                  {
                    required: true,
                    message: `${props.intl.formatMessage({ id: "required" })}`,
                    validator: () => {
                      if (!electronicFundTransfer) {
                        return Promise.reject();
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Checkbox
                  checked={electronicFundTransfer}
                  onChange={() =>
                    setElectronicFundTransfer(!electronicFundTransfer)
                  }
                />
              </Form.Item>
              <span style={{ marginLeft: "30px", paddingTop: "4px" }}>
                {props.intl.formatMessage({
                  id: "GIGWAGE_CONTRACTOR_ACCOUNT_TERMS_1",
                })}
              </span>
            </div>
            <div className="d-flex mt-5">
              <Form.Item
                name="term2"
                rules={[
                  {
                    required: true,
                    message: `${props.intl.formatMessage({ id: "required" })}`,
                    validator: () => {
                      if (!transferAgreement) {
                        return Promise.reject();
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Checkbox
                  checked={transferAgreement}
                  onChange={() => setTransferAgreement(!transferAgreement)}
                />
              </Form.Item>
              <span style={{ marginLeft: "30px", paddingTop: "4px" }}>
                {props.intl.formatMessage({
                  id: "GIGWAGE_CONTRACTOR_ACCOUNT_TERMS_2",
                })}
              </span>
            </div>

            <div className="d-flex justify-content-end">
              <Form.Item>
                <Button
                  type="submit"
                  htmlType="submit"
                  className="submit-button"
                >
                  <FormattedMessage id="submit" />
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
        <Modal
          open={signatureModal}
          footer={null}
          closeModal={() => setSignatureModal(false)}
        >
          <div className="sig-heading">
            {" "}
            <FormattedMessage id="Add_Signature" />
          </div>
          <div style={{ border: "3px solid #F77E0B", borderRadius: "5px" }}>
            <SignatureCanvas
              ref={signatureRef}
              canvasProps={{ width: 400, height: 200, className: "sigCanvas" }}
            />
          </div>
          <div className="d-flex justify-content-around mt-3">
            <button onClick={handleClearSignature} className="button-signature">
              <FormattedMessage id="clear" />
            </button>
            <button onClick={handleSaveSignature} className="button-signature">
              <FormattedMessage id="save" />
            </button>
          </div>
        </Modal>
      </div>
    </>
  );
};
export default injectIntl(AddHomAccount);
