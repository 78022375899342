import { Button } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import {
  AcceptQuickContractRequest,
  ContractAcceptRequest,
  ContractRejectRequest,
  UpdateContractRequest as UpdateSecondaryStatusContractRequest,
  UpdateContractRequest,
} from "../../api/contractsApi/acceptRejectApi";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import STRINGS from "../../utils/UserTypes";
import { checkAccountConfirmation } from "../../utils/Helper";

export const ContractDetailMainButton = ({
  userData,
  contractDetail,
  userTypeData,
  setConfirmChargesModal,
  setPaymentType,
  setAccountInfo,
  setLoading,
  setSignatureModal,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isContractUpdated =
    contractDetail?.secondary_status ===
      "contractor_changed_requirements_gigster" &&
    userData?.user_type === "tradesman";

  const isQuickPayContract =
    (contractDetail?.status === "direct_pay_to_tradesman" &&
      userData?.user_type === "tradesman") ||
    (contractDetail?.status === "direct_pay_to_contractor" &&
      userData?.user_type === "contractor") ||
    (contractDetail?.status === "direct_pay_to_homeowner" &&
      userData?.user_type === "homeowner");

  const handleAccept = async () => {
    setLoading(true);
    if (userTypeData?.sendingAccountVerify) {
      if (
        userData?.user_type === "contractor" ||
        userData?.user_type === "homeowner" ||
        (contractDetail?.contractor &&
          contractDetail?.homeowner &&
          userData?.user_type !== "tradesman")
      ) {
        if (userData?.user_type === "contractor") {
          setConfirmChargesModal(true);
        } else {
          const body = {
            contract_Id: contractDetail?.id,
            contractDetail,
          };
          await ContractAcceptRequest(body, dispatch);
        }
      } else {
        if (
          ["running", "receive"].includes(contractDetail?.status) &&
          contractDetail?.secondary_status ===
            "contractor_changed_requirements_gigster" &&
          userData?.user_type === "tradesman"
        ) {
          const body = {
            contract_Id: contractDetail?.parentContractId,
            secondary_status: "gigster_accepted_changes",
            isSubContract: true,
            subContractId: contractDetail?.id,
            contractDetail,
          };
          await UpdateContractRequest(body, navigate);
        } else {
          const body = {
            contract_Id: contractDetail?.id,
            contractDetail,
          };
          await ContractAcceptRequest(body, dispatch, navigate);
        }
      }
    } else {
      setPaymentType("receive");
      setAccountInfo(true);
    }
    setLoading(false);
  };
  const handleReject = async () => {
    setLoading(true);
    if (isContractUpdated) {
      const body = {
        contract_Id: contractDetail?.parentContractId,
        secondary_status: "gigster_rejected_changes",
        isSubContract: true,
        subContractId: contractDetail?.id,
      };
      await UpdateSecondaryStatusContractRequest(body, navigate);
    } else {
      const body = {
        contract_Id: contractDetail?.id,
        status: "reject",
      };
      await ContractRejectRequest(body, dispatch, navigate);
      setLoading(false);
    }
  };

  const getContractedUserType = () => {
    if (userData?.user_type === STRINGS.HOMEOWNER_TYPE) {
      return contractDetail?.contractorId
        ? STRINGS.CONTRACTOR_TYPE
        : STRINGS.TRADESMAN_TYPE;
    } else if (userData?.user_type === STRINGS.CONTRACTOR_TYPE) {
      return contractDetail?.homeownerId
        ? STRINGS.HOMEOWNER_TYPE
        : STRINGS.TRADESMAN_TYPE;
    } else {
      return contractDetail?.contractorId
        ? STRINGS.CONTRACTOR_TYPE
        : STRINGS.HOMEOWNER_TYPE;
    }
  };

  const acceptQuickContract = async () => {
    const contractedUserType = getContractedUserType();
    const result = checkAccountConfirmation(
      userData?.user_type,
      contractedUserType,
      contractedUserType === STRINGS.HOMEOWNER_TYPE &&
        userData?.user_type === STRINGS.CONTRACTOR_TYPE
        ? userTypeData?.receivingBankAccountVerify
        : userTypeData?.sendingAccountVerify,
      setAccountInfo
    );
    setPaymentType(result === "sender" ? "send" : result);
    if (!result) {
      if (userData?.user_type === STRINGS.HOMEOWNER_TYPE) {
        setSignatureModal(true);
      } else if (
        userData?.user_type === STRINGS.CONTRACTOR_TYPE &&
        contractedUserType === STRINGS.TRADESMAN_TYPE
      ) {
        setConfirmChargesModal(true);
      } else {
        await AcceptQuickContractRequest(
          {
            contractId: contractDetail?.id,
            status: "accept",
          },
          navigate
        );
      }
    }
  };

  if (isQuickPayContract) {
    return (
      <div className="date-div mb-5">
        <Button
          className="w-100 fw-bold mx-1 acceptContractBtn"
          onClick={acceptQuickContract}
        >
          {<FormattedMessage id="ACCEPT" />}
        </Button>
      </div>
    );
  }

  if (
    (contractDetail?.status === "send" &&
      contractDetail?.contractor &&
      userData?.user_type === "tradesman") ||
    (contractDetail?.status === "pending_approval" &&
      userData?.user_type === "homeowner") ||
    (contractDetail?.status === "pending_approval" &&
      userData?.user_type === "contractor") ||
    (contractDetail?.status === "assigned" &&
      userData?.user_type === "tradesman") ||
    (["running", "receive"].includes(contractDetail?.status) &&
      contractDetail?.secondary_status ===
        "contractor_changed_requirements_gigster" &&
      userData?.user_type === "tradesman")
  ) {
    return (
      <div className="date-div mb-5">
        <Button
          className="w-100 fw-bold mx-1"
          style={{
            color: "#F77e0b",
            border: "2px solid #F77e0b",
            height: "56px",
            width: "100px",
          }}
          onClick={handleReject}
        >
          {<FormattedMessage id="REJECT" />}
        </Button>
        <Button
          className="w-100 fw-bold mx-1 acceptContractBtn"
          onClick={handleAccept}
        >
          {<FormattedMessage id="ACCEPT" />}
        </Button>
      </div>
    );
  } else {
    return <></>;
  }
};

export const CancelContractButton = ({
  visible = false,
  contractDetail,
  setCancelModal,
}) => {
  return visible && !contractDetail?.secondary_status ? (
    <div className="mt-5">
      <Button
        onClick={() => setCancelModal(true)}
        className="w-100 fw-bold cancelContractButton"
      >
        <FormattedMessage id="CANCEL" />
      </Button>
    </div>
  ) : null;
};
