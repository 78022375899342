import { Input, Select, Switch } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import usCities from "../../assets/Cities";
import filter from "../../assets/images/filter.svg";
import usStates from "../../assets/States";
import { handleFilter } from "./service";
import { injectIntl } from "react-intl";
const Filter = ({ searchLoading, setSearchLoading, ...props }) => {
  const dispatch = useDispatch();
  const [selectedSkill, setSelectedSkill] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [switchFlag, setSwitch] = useState("");
  const [type, setType] = useState("");
  const handleSwitchChange = (flag) => {
    if (flag === "A") {
      setSwitch(flag);
      setSelectedSkill("");
      setCity("");
      setState("");
      setType("tradesman");
    } else if (flag === "B") {
      setSwitch(flag);
      setSelectedSkill("");
      setCity("");
      setState("");
      setType("contractor");
    } else if (flag === "C") {
      setSwitch(flag);
      setCity("");
      setState("");
      setType("");
    } else if (flag === "D") {
      setSwitch(flag);
      setSelectedSkill("");
      setType("");
    }
  };

  const Cities = [];
  usCities.forEach((city) => {
    if (city.state === state) {
      Cities.push({
        label: city.city,
        value: city.city,
      });
    }
  });
  const handleFilterfunc = async () => {
    setSearchLoading(true);
    await handleFilter(city, state, selectedSkill, type, dispatch);
    setSearchLoading(false);
    setSwitch("");
  };
  const lang = JSON.parse(localStorage.getItem("lang"));

  return (
    <div>
      <div
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasRight1"
        aria-controls="offcanvasRight"
      >
        <img src={filter} width={20} height={20} alt="" />
      </div>

      <div
        class="offcanvas offcanvas-end canvasWidth"
        tabindex="-1"
        id="offcanvasRight1"
        aria-labelledby="offcanvasRightLabel1"
      >
        <div class="offcanvas-header">
          <div className="d-flex w-100 justify-content-center mt-5">
            <h5
              class="offcanvas-title"
              id="offcanvasRightLabel1"
              style={{ fontFamily: "Poppins" }}
            ></h5>
          </div>

          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <div className="d-flex w-100 justify-content-between">
            <h5
              class="offcanvas-title"
              id="offcanvasRightLabel1"
              style={{ fontFamily: "Poppins" }}
            >
              {props.intl.formatMessage({ id: "filter" })}
            </h5>
            <span
              type="button"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={handleFilterfunc}
              style={{
                fontFamily: "Poppins",
                color: "#F77E0B",
                cursor: "pointer",
              }}
              className="me-2 fw-bold"
            >
              {props.intl.formatMessage({ id: "done" })}
            </span>
          </div>
          <div className="d-flex justify-content-between mt-3">
            <span className="Limit-by">
              {props.intl.formatMessage({ id: "gigster" })}
            </span>
            <Switch
              type="button"
              data-bs-toggle="canvas"
              data-bs-target="#offcanvasRight1"
              aria-controls="offcanvasRight"
              checked={switchFlag === "A" ? true : false}
              onChange={() => handleSwitchChange("A")}
            />
          </div>
          <div className="d-flex justify-content-between mt-3">
            <span className="Limit-by">
              {props.intl.formatMessage({ id: "contractor" })}
            </span>
            <Switch
              type="button"
              data-bs-toggle="canvas"
              data-bs-target="#offcanvasRight1"
              aria-controls="offcanvasRight"
              checked={switchFlag === "B" ? true : false}
              onChange={() => handleSwitchChange("B")}
            />
          </div>

          {switchFlag === "C" && (
            <div>
              <Input
                placeholder="Search Skill"
                onChange={(e) => setSelectedSkill(e.target.value)}
                style={{
                  width: "100%",
                  height: "56px",
                  marginTop: "10px",
                }}
              />
            </div>
          )}
          <div className="d-flex justify-content-between mt-3">
            <span className="Limit-by">
              {props.intl.formatMessage({ id: "limit_by_city" })}
            </span>
            <Switch
              type="button"
              data-bs-toggle="canvas"
              data-bs-target="#offcanvasRight1"
              aria-controls="offcanvasRight"
              checked={switchFlag === "D" ? true : false}
              onChange={() => handleSwitchChange("D")}
            />
          </div>
          {switchFlag === "D" && (
            <div className="row mt-2">
              <div className="col-6">
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder={
                    lang ? "Selecciona el Estado." : "Select the State"
                  }
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={usStates}
                  onSelect={(e) => setState(e)}
                />
              </div>
              <div className="col-6">
                <Select
                  showSearch
                  style={{
                    width: "100%",
                  }}
                  placeholder={
                    lang ? "Selecciona la Ciudad." : "Select the City"
                  }
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={Cities}
                  onSelect={(e) => setCity(e)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default injectIntl(Filter);
