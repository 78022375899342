import { Button } from "antd";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { GenerateGigmiCharges } from "../../api/contractsApi/contractDetails";
import { useNavigate } from "react-router";
import {
  UpdateContractRequest,
  UpdateContractStatusRequest,
} from "../../api/contractsApi/acceptRejectApi";

const ContractActions = ({
  intl,
  userData,
  contractDetail,
  userTypeData,
  fullUser,
  setConfirmChargesModal,
  setPaymentType,
  setAccountInfo,
  setLoading,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isEstimateUpdated = [
    "contractor_changed_requirements_homeowner",
    "gigster_changed_requirements_homeowner",
  ].includes(contractDetail?.secondary_status);
  const HandleDenied = async () => {
    setLoading(true);
    if (isEstimateUpdated) {
      await UpdateContractRequest(
        {
          contract_Id: contractDetail?.id,
          secondary_status: "homeowner_rejected_changes",
          isSubContract: false,
          subContractId: null,
        },
        navigate
      );
    } else {
      const body = {
        contract_Id: contractDetail?.id,
      };
      await UpdateContractStatusRequest(
        body,
        "not_assigned",
        navigate,
        contractDetail,
        intl
      );
    }
    setLoading(false);
  };
  const HandleAccept = async () => {
    if (fullUser?.sendingAccountVerify) {
      const body = {
        homeOwnerId: userTypeData?.id,
        contractorId: null,
      };
      setLoading(true);
      await GenerateGigmiCharges(body, dispatch);
      setLoading(false);
      setConfirmChargesModal(true);
    } else {
      setPaymentType("send");
      setAccountInfo(true);
    }
  };

  if (
    userData.user_type === "homeowner" &&
    (contractDetail?.status === "estimation_given" ||
      contractDetail?.status === "contract_given" ||
      [
        "contractor_changed_requirements_homeowner",
        "gigster_changed_requirements_homeowner",
      ].includes(contractDetail?.secondary_status))
  ) {
    return (
      <div className="d-flex w-100 justify-content-around date-div mb-2">
        <Button
          onClick={HandleDenied}
          className="w-100 fw-bold mx-1"
          style={{
            color: "#F77e0b",
            border: "2px solid #F77e0b",
            height: "56px",
            alignText: "center",
          }}
        >
          <FormattedMessage id="DENIED" />
        </Button>

        <Button
          onClick={HandleAccept}
          className="w-100 fw-bold mx-1"
          style={{
            color: "white",
            border: "2px solid #F77e0b",
            height: "56px",
            backgroundColor: "#F77e0b",
            alignText: "center",
          }}
        >
          {" "}
          {contractDetail?.status === "contract_given" ? (
            <FormattedMessage id="ACCEPT_CONTRACT" />
          ) : (
            <FormattedMessage id="ACCEPT_ESTI" />
          )}
        </Button>
      </div>
    );
  }

  return null;
};

export default ContractActions;
