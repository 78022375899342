import { message } from "antd";
import { setBankAccountList } from "../../actions/bankActions";
import { Axios } from "../../utils/axiosMiddleware";

export const getAccountList = async (id, dispatch) => {
  try {
    const response = await Axios.get(`gigwage/receiving/bank-account/${id}`);
    dispatch(
      setBankAccountList(
        response?.data?.accounts?.filter(
          (item) => item?.deactivated_at === null
        )
      )
    );
  } catch (e) {
    if (e.message === "Network Error") {
      message.error(`${e.message}`);
    } else {
      message.error(`${e.response.data.message}`);
    }
  }
};

export const deActivateAccount = async (id) => {
  try {
    await Axios.delete(`gigwage/deactivate/receiving/bank-account/${id}`);
    return "deleted";
  } catch (e) {
    if (e.message === "Network Error") {
      message.error(`${e.message}`);
    } else {
      message.error(`${e.response.data.message}`);
    }
  }
};
