export const IS_AUTH = "IS_AUTH";
export const IS_AUTH_SUCCESS = "IS_AUTH_SUCCESS";
export const IS_AUTH_ERROR = "IS_AUTH_ERROR";
export const INIT_SCREEN_NAME = "INIT_SCREEN_NAME";

export const GET_BANNER_IMAGES = "GET_BANNER_IMAGES";
export const GET_BANNER_IMAGES_SUCCESS = "GET_BANNER_IMAGES_SUCCESS";
export const GET_BANNER_IMAGES_ERROR = "GET_BANNER_IMAGES_ERROR";

export const GET_PROFILE_VIDEOS = "GET_PROFILE_VIDEOS";
export const GET_PROFILE_VIDEOS_SUCCESS = "GET_PROFILE_VIDEOS_SUCCESS";
export const GET_PROFILE_VIDEOS_ERRORS = "GET_PROFILE_VIDEOS_ERRORS";

export const GET_USER_DATA = "GET_USER_DATA";
export const GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS";
export const GET_USER_DATA_ERRORS = "GET_USER_DATA_ERRORS";

export const SET_FROM_SIGN_UP_TRUE = "SET_FROM_SIGN_UP_TRUE";
export const SET_FROM_SIGN_UP_FALSE = "SET_FROM_SIGN_UP_FALSE";

export const GET_ALL_STORIES = "GET_ALL_STORIES";

export const SET_SELECTED_PROFILE = "SET_SELECTED_PROFILE";
export const DELETE_SELECTED_PROFILE = "DELETE_SELECTED_PROFILE";

export const SET_VIDEOS_URL = "SET_VIDEOS_URL";

export const SET_ALL_SKILLS = "SET_ALL_SKILLS";

export const SET_USER_SKILLS_AD = "SET_USER_SKILLS_AD";

export const SET_FULL_USER_DATA = "SET_FULL_USER_DATA";

export const SET_LOGO_URL = "SET_LOGO_URL";
export const SET_PROFILE_URL = "SET_PROFILE_URL";

export const SET_REVIEW_LIST = "SET_REVIEW_LIST";

export const SET_SKILLS_LIST = "SET_SKILLS_LIST";
export const SET_USER_SKILLS_LIST = "SET_USER_SKILLS_LIST";

export const SET_IMAGES_DATA_LIST = "SET_IMAGES_DATA_LIST";
export const DELETE_IMAGES_DATA_LIST = "DELETE_IMAGES_DATA_LIST";

export const SET_STORIES_LIST = "SET_STORIES_LIST";
export const SET_STORIES_MODAL = "SET_STORIES_MODAL";
export const SET_STORIES_INDEX = "SET_STORIES_INDEX";
export const SET_YOUTUBE_MODAL = "SET_YOUTUBE_MODAL";

export const SEARCHED_PROFILES_LIST = "SEARCHED_PROFILES_LIST";

export const SET_CURRENT_USER = "SET_CURRENT_USER";

export const LIKED_USER_LIST = "LIKED_USER_LIST";
//User Actions

export const SET_CURRENT_USER_DATA = "SET_CURRENT_USER_DATA";

// BANK_INFO_ACTIONS_LIST

export const SET_GIGSTER_MODAL = "SET_GIGSTER_MODAL";
export const SET_HOMEOWNER_MODAL = "SET_HOMEOWNER_MODAL";
export const SET_CONTRACTOR_MODAL = "SET_CONTRACTOR_MODAL";
export const SET_ADD_BANK_INFO_MODAL = "SET_ADD_BANK_INFO_MODAL";
export const SET_PAYMENT_TYPE = "SET_PAYMENT_TYPE";
export const SET_ALREADY_AVAILIABLE_ACCOUNTS = "SET_ALREADY_AVAILABLE_ACCOUNTS";
export const SET_SENDER_ACCOUNT = "SET_SENDER_ACCOUNT";
export const SET_RECEIVER_ACCOUNT = "SET_RECEIVER_ACCOUNT";
export const SET_BANK_ACCOUNT_LIST = "SET_BANK_ACCOUNT_LIST";

//Earning spendings

export const SET_TOTAL_AMOUNT = "SET_TOTAL_AMOUNT";
export const SET_TRANSACTIONS_DETAILS = "SET_TRANSACTIONS_DETAILS";

//wallet details

export const SET_WALLET_TRANSACTIONS_HISTORY =
  "SET_WALLET_TRANSACTIONS_HISTORY";

//chat

export const SET_CHAT_ARRAY = "SET_CHAT_ARRAY";
export const SET_SEARCHED_CHAT_ARRAY = "SET_SEARCHED_CHAT_ARRAY";
export const SET_ALLOW_CHAT = "SET_ALLOW_CHAT";
export const SET_ALLOW_CHAT_MODAL = "SET_ALLOW_CHAT_MODAL";
export const IS_MOBILE = "IS_MOBILE";
export const GET_NEW_CHAT_MSGS = "GET_NEW_CHAT_MSGS";
export const SET_CHAT_DETAIL_MOBILE = "SET_CHAT_DETAIL_MOBILE";
export const SET_CHAT_CONVERSATION = "SET_CHAT_CONVERSATION";

//w9Form

export const SET_W9_FORM_DATA = "SET_W9_FORM_DATA";

//contracts

export const SET_CONTRACT_LISTING = "SET_CONTRACT_LISTING";
export const SET_CONTRACT_DETAILS = "SET_CONTRACT_DETAILS";
export const SET_CONNECTIONS_ARRAY = "SET_CONNECTIONS_ARRAY";
export const SET_CONTRACT_LISTING_TYPE = "SET_CONTRACT_LISTING_TYPE";
export const AWARD_TO_MYSELF_REQUEST_ERRORS = "AWARD_TO_MYSELF_REQUEST_ERRORS";
export const SET_USER_LIST_DATA = "SET_USER_LIST_DATA";
export const SET_SELECTED_USER = "SET_SELECTED_USER";

//gigmi Charges

export const SET_GIGMI_CHARGES = "SET_GIGMI_CHARGES";

export const SET_INVOICES_LIST_REQUEST = "SET_INVOICES_LIST_REQUEST";

//Feeds
export const SET_FEEDS_LISTING = "SET_FEEDS_LISTING";
export const TOTAL_FEEDS_LENGTH = "TOTAL_FEEDS_LENGTH";
export const SET_COMMENTS_LISTING = "SET_COMMENTS_LISTING";
export const SET_LIKES_LISTING = "SET_LIKES_LISTING";

//how to use the app

export const SET_HOW_TO_USE_LIST = "SET_HOW_TO_USE_LIST";

//Map

export const SET_USERS_LIST_ON_MAP = "SET_USERS_LIST_ON_MAP";
//RED DOT
export const SET_RED_DOT = "SET_RED_DOT";
export const IS_SOCKET_CONNECTED = "IS_SOCKET_CONNECTED";

//notifications

export const SET_NOTIFICATION_ARRAY = "SET_NOTIFICATION_ARRAY";
export const SET_NOTIFICATION_COUNT = "SET_NOTIFICATION_COUNT";

export const SET_UNSUBSCRIBED_USER = "SET_UNSUBSCRIBED_USER";
