import { SimpleCatchError } from "../../utils/Helper";
import { Axios } from "../../utils/axiosMiddleware";
import { sendChatMessageViaContract } from "../../sockets/ChatSockets";
export const ContractAcceptRequest = async (payload, navigate) => {
  try {
    const token = JSON.parse(localStorage.getItem("data"));
    const { contract_Id } = payload;
    await Axios.patch(`message-files/contract_status/${contract_Id}/receive`, {
      user_id: token?.user_id,
      user_type: token?.user_type,
    });
    navigate("/contract-lists");
  } catch (e) {
    SimpleCatchError(e);
  }
};

export const ContractRejectRequest = async (payload, dispatch, navigate) => {
  try {
    const token = JSON.parse(localStorage.getItem("data"));
    const { contract_Id, status, secondary_status } = payload;
    await Axios.patch(
      `message-files/contract_status/${contract_Id}/${status}`,
      {
        user_id: token?.user_id,
        user_type: token?.user_type,
        secondary_status,
      }
    );
    navigate("/contract-lists");
  } catch (e) {
    SimpleCatchError(e);
  }
};

export const UpdateContractStatusRequest = async (
  status,
  navigate,
  contractDetail,
  intl,
  payload = {}
) => {
  try {
    const token = JSON.parse(localStorage.getItem("data"));
    const { contract_Id, disputeReason } = payload;
    await Axios.patch(
      `/message-files/contract_status/${contract_Id}/${status}`,
      {
        reason: disputeReason,
        user_id: token?.user_id,
        user_type: token?.user_type,
      }
    );

    if (status === "assigned") {
      const conversation = await Axios.post(
        `/message-files/conversation/${token.id}/${
          contractDetail?.contractorId
            ? contractDetail?.contractorId
            : contractDetail?.tradesmanId
        }/${token.user_type}/${
          contractDetail?.contractorId ? "contractor" : "tradesman"
        }/0/0`,
        {}
      );

      if (conversation?.data?.id) {
        sendChatMessageViaContract({
          message: `${intl.formatMessage({
            id: "CONTRACT_HAS_BEEN_ASSIGNED",
          })} ${contract_Id}`,
          sender: token?.id,
          receiver: contractDetail?.contractorId
            ? contractDetail?.contractorId
            : contractDetail?.tradesmanId,
          room: conversation?.data?.id,
          type: "work_request",
          user_type: token.user_type,
          receiver_type: contractDetail?.contractorId
            ? "contractor"
            : "tradesman",
        });
      }
    }
    navigate("/contract-lists");
  } catch (e) {
    SimpleCatchError(e);
  }
};

export const UpdateContractRequest = async (payload, navigate) => {
  try {
    const token = JSON.parse(localStorage.getItem("data"));
    const {
      contract_Id,
      secondary_status,
      isSubContract,
      subContractId,
      line_items,
      start_date,
      end_date,
    } = payload;

    await Axios.patch(`/message-files/contract/change/request/${contract_Id}`, {
      user_id: token?.user_id,
      user_type: token?.user_type,
      secondary_status,
      isSubContract,
      subContractId,
      line_items,
      start_date,
      end_date,
    });

    navigate("/contract-lists");
  } catch (e) {
    SimpleCatchError(e);
  }
};

export const AcceptQuickContractRequest = async (payload, navigate) => {
  try {
    await Axios.post(`message-files/contract/paid`, payload);
    navigate("/contract-lists");
  } catch (e) {
    SimpleCatchError(e);
  }
};

export const AwardToMyselfSaga = async (payload, dispatch, navigate) => {
  try {
    const token = JSON.parse(localStorage.getItem("data"));
    const { contract_Id } = payload;
    await Axios.post(`/message-files/transfer/contract-to-tradesman/account`, {
      contractId: contract_Id,
      userId: token?.user_id,
    });
    navigate("/contract-lists");
  } catch (e) {
    SimpleCatchError(e);
  }
};
