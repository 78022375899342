import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  GenerateGigmiCharges,
  GetContractDetails,
} from "../../api/contractsApi/contractDetails";
import { useDispatch, useSelector } from "react-redux";
import { CompanyProfileCard } from "../../components/Contracts/CompanyProfileCard";
import Header from "../../components/Header/Header";
import { ContractorProfileCard } from "../../components/Contracts/ContractorProfileCard";
import { HomeownerProfileCard } from "../../components/Contracts/HomeownerProfileCard";
import { TradesmanProfileCard } from "../../components/Contracts/TradesmanProfileCard";
import ContractToggleButton from "../../components/Contracts/ContractToggleButton";
import { ContractDatesCard } from "../../components/Contracts/ContractDatesCard";
import { isEmpty } from "lodash";
import { FormattedMessage, injectIntl } from "react-intl";
import { formatDate } from "../../components/Contracts/helper";
import { ContractDetailCard } from "../../components/Contracts/ContractDetailCard";
import { CheckInButton } from "../../components/Contracts/CheckInButton";
import { ContractLogs } from "../../components/Contracts/ContractLogs";
import { ProofOfWorkCard } from "../../components/Contracts/ProofOfWorkCard";
import { ContractDetailMainButton } from "../../components/Contracts/ContractDetailsMainButton";
import SubmitProofOfWorkModal from "./SubmitProofOfWork";
import VerificationModal from "../../components/Contracts/VerificationModal";
import Loader from "../../components/GeneralComponents";
import ContractAmountDetailsModal from "../../components/Contracts/ContractAmountDetailModal";
import {
  AcceptQuickContractRequest,
  AwardToMyselfSaga,
  ContractAcceptRequest,
  UpdateContractRequest,
  UpdateContractStatusRequest,
} from "../../api/contractsApi/acceptRejectApi";
import ContractActions from "../../components/Contracts/ContractActions";
import backIcon from "../../assets/images/backButton.svg";
import { RightIconGenerator } from "../../components/Contracts/ContractDetailRightIcons";
import { useNavigate } from "react-router";
import { ContractButtons } from "../../components/Contracts/ContractButtons";
import DisputeModal from "../../components/Contracts/DisputeModal";
import { message, Modal } from "antd";
import SignatureCanvas from "react-signature-canvas";
import Disclaimer from "../../components/BankInfo/Disclaimer";
import { cancelContractRequest } from "../../api/contractsApi/cancelContractApi";
import {
  capitalizeTo,
  getContractedUser,
  getUserSkills,
} from "../../utils/Helper";
import STRINGS from "../../utils/UserTypes";
const ContractDetails = ({ intl }) => {
  const signatureRef = useRef();
  const [modalVisible, setModalVisible] = useState(false);
  const [disputeModal, setDisputeModal] = useState(false);
  const [disputeReason, setDisputeReason] = useState("");
  const [verificationModal, setVerificationModal] = useState(false);
  const [awardToSelfModal, setAwardToSelfModal] = useState(false);
  const [isGigsterAvailable, setIsGigsterAvailable] = useState(false);
  const [chargesModal, setChargesModal] = useState(false);
  const [confirmChargesModal, setConfirmChargesModal] = useState(false);
  const [signatureModal, setSignatureModal] = useState(false);
  const [paymentType, setPaymentType] = useState(null);
  const [accountInfo, setAccountInfo] = useState(false);
  const [contractState, setContractState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);

  const searchParams = new URLSearchParams(window.location.search);
  const id = searchParams.get("id");
  const dispatch = useDispatch();
  const { contractDetails: contractDetail } = useSelector(
    (state) => state?.contracts
  );
  const userTypeData = useSelector((state) => state?.common?.newUser?.data);
  const fullUser = useSelector((state) => state?.common?.fullUser);
  const gigmiCharges = useSelector((state) => state?.gigmiCharges?.charges);
  const userData = JSON.parse(localStorage.getItem("data"));
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      setLoading(true);
      await GetContractDetails(id, dispatch);
      setLoading(false);
    })();
  }, [id]);

  useEffect(() => {
    const userContractedType = getContractedUser(userData, contractDetail);

    if (userContractedType?.id) {
      (async () => {
        await getUserSkills(
          {
            receiverID: userContractedType?.id,
            receiver_type: userContractedType?.user_contracted_type,
          },
          dispatch,
          intl
        );
      })();
    }
  }, [contractDetail]);

  useEffect(() => {
    const handleFocus = () => {
      if (userData?.user_type !== "tradesman") {
        const body = {
          homeOwnerId:
            userData?.user_type === "contractor" ? null : userTypeData?.id,
          contractorId:
            userData?.user_type === "contractor" ? userTypeData?.id : null,
        };
        (async () => {
          await GenerateGigmiCharges(body, dispatch);
        })();
      }
      if (
        contractDetail?.secondary_status &&
        ((contractDetail?.contractor &&
          contractDetail?.homeowner &&
          (userData?.user_type === "homeowner" ||
            userData?.user_type === "contractor")) ||
          (contractDetail?.contractor &&
            contractDetail?.tradesman &&
            contractDetail?.parentContractId &&
            contractDetail?.updated_line_item?.length > 0 &&
            (userData?.user_type === "contractor" ||
              userData?.user_type === "tradesman")))
      ) {
        setContractState(true);
      }
    };
    handleFocus();
  }, []);

  const getCalculatedStartDate = () => {
    return !isEmpty(contractDetail?.dates_record) &&
      contractDetail?.dates_record?.length > 0
      ? contractDetail?.dates_record[contractDetail?.dates_record?.length - 1]
          ?.start_date
      : contractDetail?.dates_record[0]?.start_date;
  };

  const getCalculatedEndDate = useCallback(() => {
    return !contractState && contractDetail?.dates_record?.length > 0
      ? contractDetail?.dates_record[contractDetail?.dates_record?.length - 1]
          ?.end_date
      : contractDetail?.dates_record[0]?.end_date;
  }, [contractState, contractDetail]);

  const calculateFlatAmount = () => {
    const contractStatuses =
      contractDetail?.homeownerId && !contractDetail?.tradesmanId
        ? [
            "contractor_changed_requirements_homeowner",
            "homeowner_accepted_changes",
          ]
        : [
            "contractor_changed_requirements_gigster",
            "gigster_changed_requirements_homeowner",
            "gigster_accepted_changes",
          ];

    if (
      !contractState &&
      contractStatuses?.includes(contractDetail?.secondary_status)
    ) {
      const flatAmount = parseInt(contractDetail?.changed_flat_amount);
      return flatAmount || 0;
    } else if (contractDetail?.updated_flat_amount) {
      const flatAmount = parseInt(contractDetail?.updated_flat_amount);
      return flatAmount || 0;
    } else {
      const flatAmount = parseInt(contractDetail?.flat_amount);
      return flatAmount || 0;
    }
  };

  const gigMiFeeCalculations = useMemo(
    () =>
      userData.user_type === "homeowner" &&
      [
        "contractor_changed_requirements_homeowner",
        "gigster_changed_requirements_homeowner",
      ].includes(contractDetail?.secondary_status),
    [contractDetail?.secondary_status, userData.current_type]
  );

  const getJustifyContent = (contractDetail) => {
    if (
      (contractDetail?.contractor && contractDetail?.tradesman) ||
      (contractDetail?.contractor && contractDetail?.homeowner) ||
      (contractDetail?.tradesman && contractDetail?.homeowner)
    ) {
      return "space-around";
    } else {
      return "center";
    }
  };

  const getReviews = () => {
    const reviews = [];

    if (contractDetail?.review?.length > 0) {
      contractDetail?.review?.forEach((element) => {
        if (element?.proof.length > 0) {
          reviews?.push(element);
        }
      });
    }
    return reviews;
  };

  const handleFlatAmountOnPress = async () => {
    let body = {};
    if (userData?.user_type !== STRINGS.TRADESMAN_TYPE) {
      if (userData?.user_type === STRINGS.HOMEOWNER_TYPE) {
        body = {
          homeOwnerId: userTypeData?.id,
        };
      } else if (contractDetail?.homeownerId) {
        return;
      } else {
        body = {
          contractorId: userTypeData?.id,
        };
      }
      setLoading(true);
      await GenerateGigmiCharges(body, dispatch);
      setLoading(false);
      setChargesModal(true);
    }
  };

  const handleDisputeModal = async () => {
    const body = {
      contract_Id: contractDetail?.id,
      disputeReason: disputeReason,
    };
    if (disputeReason?.length > 0) {
      await UpdateContractStatusRequest(
        body,
        "disputed",
        navigate,
        contractDetail,
        intl
      );
    } else {
      message.error(intl.formatMessage({ id: "PLEASE_ADD_DISPUTE_REASON" }));
    }
  };

  const handleSaveSignature = async () => {
    const signatureDataURL = signatureRef.current?.toDataURL();
    const base64Signature = signatureDataURL.split(",")[1];
    if (base64Signature) {
      setSignatureModal(false);
      if (contractDetail?.secondary_status) {
        await UpdateContractRequest(
          {
            contract_Id: contractDetail?.id,
            secondary_status: [
              "contractor_changed_requirements_homeowner",
              "gigster_changed_requirements_homeowner",
            ].includes(contractDetail?.secondary_status)
              ? "homeowner_accepted_changes"
              : undefined,
            isSubContract: false,
            contractDetail: contractDetail,
          },
          navigate
        );
      } else if (
        contractDetail?.status ===
        "direct_pay_to_" + userData?.user_type
      ) {
        setLoading(true);
        await AcceptQuickContractRequest(
          {
            contractId: contractDetail?.id,
            status: "accept",
          },
          navigate
        );
        setLoading(false);
      } else {
        await UpdateContractStatusRequest(
          { contract_Id: contractDetail?.id },
          "assigned",
          navigate,
          contractDetail,
          intl
        );
      }
    }
  };

  const handleClearSignature = () => {
    signatureRef.current?.clear();
  };

  const navigateToReceivePayment = (sendPayment = false) => {
    if (fullUser?.receivingAccountVerify && fullUser?.kyc) {
      navigate("/bank-info");
    } else if (fullUser?.receivingAccountVerify) {
      navigate("/w9-form");
    } else {
      navigate("/get-payments");
    }
  };

  return (
    <>
      {loading && (
        <div className="search-loader">
          <Loader />
        </div>
      )}

      <div className="container">
        <Header />
        <div className="paymentscreen-inner">
          <div className="d-flex justify-content-between align-items-center pb-3">
            <span className="d-flex align-items-center">
              <img
                src={backIcon}
                alt=""
                onClick={() => navigate(-1)}
                style={{ cursor: "pointer" }}
                className="shadow-lg border rounded"
              />
              <span className="text-black fs-4 m-1">
                <FormattedMessage id="back" />
              </span>
            </span>
            <span className="fw-bold fs-5">
              <FormattedMessage id="contract-details" />
            </span>
            <span>
              <RightIconGenerator
                contractDetail={contractDetail}
                userData={userData}
              />
            </span>
          </div>

          <CompanyProfileCard contractDetail={contractDetail} />
          <div
            style={{
              display: "flex",
              justifyContent: getJustifyContent(contractDetail),
            }}
            className="w-100"
          >
            <ContractorProfileCard
              contractDetail={contractDetail}
              userData={userData}
              userTypeData={userTypeData}
            />
            <HomeownerProfileCard
              contractDetail={contractDetail}
              userData={userData}
              userTypeData={userTypeData}
            />
            <TradesmanProfileCard
              contractDetail={contractDetail}
              userData={userData}
              userTypeData={userTypeData}
            />
          </div>
          <ContractToggleButton
            contractDetail={contractDetail}
            userData={userData}
            contractState={contractState}
            setContractState={setContractState}
          />
          {!isEmpty(contractDetail?.dates_record) && (
            <ContractDatesCard
              startDate={getCalculatedStartDate()}
              endDate={getCalculatedEndDate()}
            />
          )}
          <div className="d-flex justify-content-center w-100 mt-3">
            <span className="fw-bold text-center mt-3">
              <FormattedMessage id="created_at" />{" "}
              {formatDate(contractDetail?.created_at)}
            </span>
          </div>
          {contractDetail && (
            <ContractDetailCard
              contractDetails={contractDetail}
              showDescription={true}
              contractState={contractState}
              flatAmountOnPress={handleFlatAmountOnPress}
              contract_location={
                contractDetail?.contract_location &&
                contractDetail?.contract_location[0]
              }
              userData={userData}
            />
          )}
          <CheckInButton
            userData={userData}
            contractLogs={contractDetail?.contract_logs}
            status={contractDetail?.status}
            contractDetail={contractDetail}
            setLoading={setLoading}
          />
          <ContractLogs contractLogs={contractDetail?.contract_logs} />
          {contractDetail?.review?.length > 0 && (
            <ProofOfWorkCard contractDetail={contractDetail} />
          )}
          <ContractDetailMainButton
            userData={userData}
            contractDetail={contractDetail}
            userTypeData={fullUser}
            dispatch={dispatch}
            setConfirmChargesModal={setConfirmChargesModal}
            setPaymentType={setPaymentType}
            setAccountInfo={setAccountInfo}
            setLoading={setLoading}
            setSignatureModal={setSignatureModal}
          />
          <ContractActions
            intl={intl}
            userData={userData}
            contractDetail={contractDetail}
            dispatch={dispatch}
            setSignatureModal={setSignatureModal}
            userTypeData={userTypeData}
            fullUser={fullUser}
            setConfirmChargesModal={setConfirmChargesModal}
            setPaymentType={setPaymentType}
            setAccountInfo={setAccountInfo}
            setLoading={setLoading}
          />

          <ContractButtons
            userData={userData}
            contractDetail={contractDetail}
            setCancelModal={setCancelModal}
            setDisputeModal={setDisputeModal}
            setModalVisible={setModalVisible}
            setAwardToSelfModal={setAwardToSelfModal}
            reviews={getReviews()}
          />
        </div>
        <SubmitProofOfWorkModal
          visible={modalVisible}
          onClose={() => setModalVisible(false)}
        />
        <DisputeModal
          visible={disputeModal}
          title={<FormattedMessage id="DISPUTE" />}
          setReason={setDisputeReason}
          description={<FormattedMessage id="PROVIDE_DISPUTE_DETAILS" />}
          cancelAction={() => setDisputeModal(false)}
          confirmAction={handleDisputeModal}
          cancelText={<FormattedMessage id="CANCEL" />}
          confirmText={<FormattedMessage id="submit" />}
        />
        <VerificationModal
          visible={verificationModal}
          onClose={() => setVerificationModal(false)}
          userTypeData={userTypeData}
          userData={userData}
        />
        <ContractAmountDetailsModal
          visible={confirmChargesModal}
          title={<FormattedMessage id="CONTRACT_AMOUNT_DETAIL" />}
          contractId={contractDetail?.id}
          gigmiCharges={gigMiFeeCalculations ? 0 : gigmiCharges}
          flatAmount={calculateFlatAmount()}
          cancelText={<FormattedMessage id="CANCEL" />}
          cancelAction={() => setConfirmChargesModal(false)}
          confirmText={<FormattedMessage id="CONFIRM_CAPITALS" />}
          confirmAction={async () => {
            setConfirmChargesModal(false);
            if (
              userData.user_type === "homeowner" &&
              ([
                "estimation_given",
                "direct_pay_to_" + userData?.user_type,
                "contract_given",
              ].includes(contractDetail?.status) ||
                [
                  "contractor_changed_requirements_homeowner",
                  "gigster_changed_requirements_homeowner",
                ].includes(contractDetail?.secondary_status))
            ) {
              setSignatureModal(true);
            } else if (
              contractDetail?.status ===
              "direct_pay_to_" + userData?.user_type
            ) {
              setLoading(true);
              await AcceptQuickContractRequest(
                {
                  contractId: contractDetail?.id,
                  status: "accept",
                },
                navigate
              );
              setLoading(false);
            } else {
              const body = {
                contract_Id: contractDetail?.id,
                contractDetail,
              };
              setLoading(true);
              await ContractAcceptRequest(body, dispatch, navigate);
              setLoading(false);
            }
          }}
        />
        <ContractAmountDetailsModal
          visible={chargesModal}
          title={<FormattedMessage id="CONTRACT_AMOUNT_DETAIL" />}
          contractId={contractDetail?.id}
          gigmiCharges={gigMiFeeCalculations ? 0 : gigmiCharges}
          flatAmount={calculateFlatAmount()}
          cancelText={<FormattedMessage id="CANCEL" />}
          cancelAction={() => setChargesModal(false)}
        />
        <Modal
          open={signatureModal}
          footer={null}
          onCancel={() => setSignatureModal(false)}
        >
          <div className="sig-heading">Add Signature</div>
          <div style={{ border: "3px solid #F77E0B", borderRadius: "5px" }}>
            <SignatureCanvas
              ref={signatureRef}
              canvasProps={{ width: 400, height: 200, className: "sigCanvas" }}
            />
          </div>
          <div className="d-flex justify-content-around mt-3">
            <button onClick={handleSaveSignature} className="button-signature">
              Save
            </button>
            <button onClick={handleClearSignature} className="button-signature">
              Clear
            </button>
          </div>
        </Modal>
        <Disclaimer
          showModal={awardToSelfModal}
          title={<FormattedMessage id="Please Verify" />}
          body={
            <FormattedMessage id="This contract will be awarded to your Gigster profile on this account. Do you want to continue?" />
          }
          cancelText={<FormattedMessage id="cancel" />}
          confirmText={<FormattedMessage id="CONFIRM" />}
          handleCancel={() => setAwardToSelfModal(false)}
          handleClick={async () => {
            setAwardToSelfModal(false);
            setLoading(true);
            if (
              fullUser?.availabilityRecord?.includes(STRINGS.TRADESMAN_TYPE)
            ) {
              await AwardToMyselfSaga(
                { contract_Id: contractDetail?.id },
                dispatch,
                navigate
              );
            } else {
              setIsGigsterAvailable(true);
            }
            setLoading(false);
          }}
        />
        <Disclaimer
          showModal={isGigsterAvailable}
          title={<FormattedMessage id="Please Hold On" />}
          body={
            <FormattedMessage id="Gigster profile is not Linked to this account. Please create Gigster profile before continuing." />
          }
          cancelText={<FormattedMessage id="cancel" />}
          handleCancel={() => setIsGigsterAvailable(false)}
        />
        <Disclaimer
          showModal={cancelModal}
          body={
            <FormattedMessage id="Are you sure you want to cancel this contract" />
          }
          cancelText={<FormattedMessage id="cancel" />}
          confirmText={<FormattedMessage id="CONFIRM" />}
          handleClick={async () => {
            setLoading(true);
            setCancelModal(false);
            await cancelContractRequest(
              {
                contractId: contractDetail?.id || null,
                contractCancelBy: userData?.user_type,
              },
              navigate
            );
            setLoading(false);
          }}
          handleCancel={() => setCancelModal(false)}
        />
        <Disclaimer
          showModal={accountInfo}
          setShowModal={() => setAccountInfo(false)}
          body={capitalizeTo(
            `${userData?.user_type} ${intl.formatMessage({
              id: "needs_to_add",
            })} ${paymentType} ${intl.formatMessage({
              id: "account_information",
            })}`
          )}
          cancelText={<FormattedMessage id="no" />}
          confirmText={<FormattedMessage id="yes" />}
          handleClick={() => {
            setAccountInfo(false);
            if (userData?.user_type === STRINGS.TRADESMAN_TYPE) {
              setVerificationModal(true);
            } else {
              if (paymentType === "send") {
                navigate("/add-hom-account?path=new");
              } else if (fullUser?.receivingAccountVerify && fullUser?.kyc) {
                navigate("/bank-info");
              } else if (fullUser?.receivingAccountVerify) {
                navigate("/w9-form");
              } else {
                navigate("/get-payments");
              }
            }
          }}
          handleCancel={() => {
            setAccountInfo(false);
          }}
        />
      </div>
    </>
  );
};

export default injectIntl(ContractDetails);
