import { Modal } from "antd";
import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import Wallet from "../../assets/images/editBank.svg";
import { setHomeownerModal } from "../../actions/bankActions";
import { useNavigate } from "react-router-dom";

const HomeownerModal = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const homeownerModal = useSelector(
    (state) => state?.bankInfo?.homeownerModal
  );
  const handleCancel = () => {
    dispatch(setHomeownerModal(false));
  };
  const handleWalletImageClick = () => {
    dispatch(setHomeownerModal(false));
    navigate("/add-hom-account?path=Edit");
  };
  return (
    <Modal
      open={homeownerModal}
      footer={null}
      destroyOnClose={true}
      onCancel={handleCancel}
    >
      <div className="d-flex justify-content-around align-items-center">
        <div className="d-flex flex-column justify-content-center align-items-center">
          <img
            src={Wallet}
            className="bank-modal-image"
            onClick={handleWalletImageClick}
          />
          <span className="bank-modal-text">
            {props.intl.formatMessage({ id: "edit_bank" })}
          </span>
        </div>
      </div>
    </Modal>
  );
};
export default injectIntl(HomeownerModal);
