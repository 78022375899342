import { Button, Form, Input, Radio, message } from "antd";
import React, { useState } from "react";
import "./BankInfo.css";
import Header from "../../components/Header/Header";
import { useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  PaymentScreenOneSubmit,
  changeGigwageEmail,
} from "../../api/bankApi/PaymentScreenOneApi";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../components/GeneralComponents";
import { BackButton } from "../../components/General/BackButton";
const PaymentScreenOne = (props) => {
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const path = queryParams.get("path");
  const [form] = Form.useForm();
  const userType = useSelector((state) => state?.common?.fullUser);

  const handleSubmit = async (values) => {
    if (values?.email === values.confirmEmail && state?.path !== "edit_email") {
      const body = {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email?.trim(),
        userId: userType?.id,
      };
      setLoading(true);
      const res = await PaymentScreenOneSubmit(body);
      setLoading(false);
      if (res) {
        navigate(`/w9-form?path=${path}`);
      }
    } else if (
      values?.email === values.confirmEmail &&
      state?.path == "edit_email"
    ) {
      const body = {
        email: values.email?.trim(),
        accountType: values?.accountType,
      };
      setLoading(true);
      await changeGigwageEmail(body, userType?.id, navigate);
      setLoading(false);
    }
  };
  return (
    <>
      {loading && (
        <div className="search-loader">
          <Loader />
        </div>
      )}
      <BackButton />
      <div className="container paymentscreen">
        <Header />
        <div className="paymentscreen-inner">
          <div className="d-flex justify-content-center align-items-center getting-payment-heading shadow border">
            <span>
              <FormattedMessage id="getting_payments" />
            </span>
          </div>
          <Form
            onFinish={handleSubmit}
            form={form}
            layout="vertical"
            initialValues={{
              firstName: userType?.first_name ? userType?.first_name : "",
              lastName: userType?.last_name ? userType?.last_name : "",
              email:
                state?.path !== "edit_email" &&
                userType?.username?.includes("@")
                  ? userType?.username
                  : "",
              confirmEmail: "",
            }}
          >
            {state?.path !== "edit_email" && (
              <div className="row">
                <div className="col-6">
                  <Form.Item
                    name="firstName"
                    label={props.intl.formatMessage({ id: "first_name" })}
                    rules={[
                      {
                        required: true,
                        message: `${props.intl.formatMessage({
                          id: "required",
                        })}`,
                      },
                    ]}
                  >
                    <Input
                      placeholder={props.intl.formatMessage({
                        id: "first_name",
                      })}
                      className="input-field"
                    />
                  </Form.Item>
                </div>
                <div className="col-6">
                  <Form.Item
                    name="lastName"
                    label={props.intl.formatMessage({ id: "last_name" })}
                    rules={[
                      {
                        required: true,
                        message: `${props.intl.formatMessage({
                          id: "required",
                        })}`,
                      },
                    ]}
                  >
                    <Input
                      placeholder={props.intl.formatMessage({
                        id: "last_name",
                      })}
                      className="input-field"
                    />
                  </Form.Item>
                </div>
              </div>
            )}
            <Form.Item
              name="email"
              label={props.intl.formatMessage({ id: "email" })}
              rules={[
                {
                  required: true,
                  message: `${props.intl.formatMessage({ id: "required" })}`,
                },
              ]}
            >
              <Input
                className="input-field"
                placeholder={props.intl.formatMessage({ id: "email" })}
              />
            </Form.Item>
            <Form.Item
              name="confirmEmail"
              label={props.intl.formatMessage({ id: "confirm_email" })}
              rules={[
                {
                  required: true,
                  message: `${props.intl.formatMessage({ id: "required" })}`,
                },
              ]}
            >
              <Input
                className="input-field"
                placeholder={props.intl.formatMessage({ id: "confirm_email" })}
              />
            </Form.Item>
            {state?.path === "edit_email" && (
              <>
                <div className="d-flex justify-content-center fs-4">
                  <FormattedMessage id="account_type_select" />
                </div>
                <Form.Item
                  name="accountType"
                  className="d-flex justify-content-center mt-4"
                  rules={[
                    {
                      required: true,
                      message: `${props.intl.formatMessage({
                        id: "required",
                      })}`,
                    },
                  ]}
                >
                  <Radio.Group name="radiogroup">
                    <Radio value="receiver" className="email-text">
                      {props.intl.formatMessage({ id: "receiver" })}
                    </Radio>
                    <Radio value="sender" className="email-text">
                      {props.intl.formatMessage({ id: "sender" })}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </>
            )}
            <div className="d-flex justify-content-end">
              <Form.Item>
                <Button
                  type="submit"
                  htmlType="submit"
                  className="submit-button"
                >
                  <FormattedMessage id="submit" />
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};
export default injectIntl(PaymentScreenOne);
