import React, { useEffect, useState } from "react";
import { ReceiptCard } from "../../components/ReceiptCard/ReceiptCard";
import { useDispatch, useSelector } from "react-redux";
import { getInvoices } from "../../api/invoicesApi/invoicesApi";
import { Input } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import { SearchOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { BackButton } from "../../components/General/BackButton";
import { AppColors } from "../../assets/colors";
import AppLogo from "../../assets/images/AppLogo.png";
import { handleReload } from "../../components/Header/Helper";
import { useNavigate } from "react-router";
import { getProfile } from "../../api/GetProfile";
import ShareModal from "../../components/VideoCard/ShareModal";

const GenerateReceiptsAndInvoicesScreen = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const invoicesData = useSelector((state) => state?.invoices?.invoicesData);
  const searchParams = new URLSearchParams(window.location.search);
  const user_id = searchParams.get("user_id");
  const id = searchParams.get("id");
  const user_type = searchParams.get("type");
  const path = searchParams.get("path");
  const [showShareModal, setShowShareModal] = useState(false);
  const [shareFileURL, setShareFileURL] = useState("");

  useEffect(() => {
    getInvoices(
      dispatch,
      "",
      user_type == "tradesman" ? id : null,
      user_type == "homeowner" ? id : null
    );
    if (path) {
      getProfile(dispatch, user_id, id, user_type);
    }
  }, []);

  const handleSearch = (searchText) => {
    getInvoices(dispatch, searchText);
  };

  return (
    <div className="container pb-5 ">
      <img
        src={AppLogo}
        alt="logo"
        height={40}
        style={{ cursor: "pointer", position: "absolute" }}
        onClick={() => handleReload(navigate)}
      />
      <div className="paymentscreen-inner mt-5 ">
        <div
          className="d-flex justify-content-between align-items-center pb-4 pt-5"
          style={{ cursor: "pointer" }}
        >
          <BackButton top="0px" left="0px" position="relative" />
          <div className="help_heading">
            {props.intl.formatMessage({
              id: path
                ? user_type === "tradesman"
                  ? "RECEIPTS"
                  : "INVOICES"
                : "INVOICES_RECEIPTS",
            })}
          </div>
          <div
            onClick={() =>
              path
                ? navigate(
                    `/create-invoices-or-receipts/?user_id=${user_id}&type=${user_type}&id=${id}&path=${path}`
                  )
                : navigate(`/create-invoices-or-receipts`)
            }
            className="help_sub_heading flex-row align-items-center"
            style={{ color: AppColors.PRIMARY_COLOR }}
          >
            <span style={{ marginRight: 12 }}>
              {props.intl.formatMessage({ id: "CREATE" })}
            </span>
            <PlusSquareOutlined />
          </div>
        </div>
        <div className="d-sm-none d-none d-md-flex">
          <Input
            className="search-input"
            type="text"
            onPressEnter={handleSearch}
            onSubmit={handleSearch}
            onChange={(e) => handleSearch(e.target.value)}
            placeholder={props.intl.formatMessage({ id: "header.search" })}
            prefix={<SearchOutlined />}
          />
        </div>
      </div>
      {invoicesData?.length === 0 ? (
        <div className="d-flex justify-content-center mt-5">
          <span>
            <FormattedMessage id="N0_INVOICES_FOUND" />
          </span>
        </div>
      ) : (
        <div className="paymentscreen-inner mt-5">
          {invoicesData?.map((item, index) => (
            <ReceiptCard
              item={item}
              shareOnPress={(fileURL) => {
                setShareFileURL(fileURL);
                setShowShareModal(true);
              }}
            />
          ))}
        </div>
      )}
      <ShareModal
        showModal={showShareModal}
        url={shareFileURL}
        title="check this"
        setShowModal={setShowShareModal}
        showDownloadOption={true}
      />
    </div>
  );
};

export default injectIntl(GenerateReceiptsAndInvoicesScreen);
