import { EnvironmentOutlined } from "@ant-design/icons";
import { Divider } from "antd";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSearchedProfilesList } from "../../actions/CommonActions";
import backIcon from "../../assets/images/backButton.svg";
import reportIcon from "../../assets/images/Report.svg";
import profileIcon from "../../assets/images/profileIcon.png";
import star from "../../assets/images/star.png";
import playIcon from "../../assets/images/playvideo.png";
import thumbNail from "../../assets/images/video.png";
import {
  getFullUser,
  getProfileVideosData,
  getProfileVideosData1,
} from "../../views/Home/Service";
import Footer from "../Footer/Footer";
import Loader from "../GeneralComponents";
import ImageViewModal from "../UserProfile/ImageViewModal";
import { VideoCard } from "../VideoCard/VideoCard";
import { getUserReviews } from "./service";
import { FormattedMessage, injectIntl } from "react-intl";
import { ReportProfile } from "./ReportProfile";
import { getProfile } from "../../api/GetProfile";
import { leaveConversation } from "../../sockets/ChatSockets";
import { setChatDetailMobile } from "../../actions/ChatActions";
import { loadConversation } from "../../api/chatApi/loadConversation";
import { getContractValues } from "../../utils/Helper";
import { GetAdminSkills } from "../../api/contractsApi/getSkillsApi";
import { bucket_base_url } from "../../constants";
import VideoPlayModal from "../UserProfile/VideoPlayModal";

function TradProfile(props) {
  const [viewMoreLoading, setViewMoreLoading] = useState(false);
  const searchParams = new URLSearchParams(window.location.search);
  const id = searchParams.get("id");
  const userTypeFromUrl = searchParams.get("type");
  const user_id = searchParams.get("user-id");
  const navigate = useNavigate();
  const [reviewLoading, setReviewLoading] = useState(false);
  const userType = userTypeFromUrl;
  const userData = useSelector((state) => state?.common?.userData?.data);
  const reviewsList = useSelector((state) => state?.common?.reviewsList);
  const token = JSON.parse(localStorage.getItem("data"));
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [skills, setSkills] = useState([]);
  const [videoCurrentIndex, setVideoCurrentIndex] = useState("");
  const [showVideoModal, setShowVideoModal] = useState(false);

  const profileVideos = useSelector(
    (state) => state?.common?.profileVideosList?.data?.users
  );
  const profileCount = useSelector(
    (state) => state?.common?.profileVideosList?.data?.totalUsers
  );
  const userTypeData = useSelector((state) => state?.common?.fullUser);
  const profile = useSelector((state) => state?.common?.profile);
  const type =
    userType === "tradesman"
      ? profile?.tradesman
      : userType === "contractor"
      ? profile?.contractor
      : profile?.homeowner;
  const files =
    userType === "tradesman"
      ? profile?.tradesman?.tradesman_files
      : userType === "contractor"
      ? profile?.contractor?.contractor_files
      : null;
  const videos =
    !isEmpty(files) && files?.filter((item) => item?.type === "video");

  const getSkills = async () => {
    const res = await GetAdminSkills();
    setSkills(res);
  };

  useEffect(() => {
    getSkills();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await getProfile(dispatch, user_id, id, userType);
      await getProfileVideosData(dispatch, userData?.usersWithCompleteProfile);
      await getUserReviews(dispatch, id, userType);
      await getSkills();
      await getFullUser(dispatch);
      setLoading(false);
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchReviews = async () => {
      setReviewLoading(true);
      await getUserReviews(dispatch, id, userType);
      setReviewLoading(false);
    };
    fetchReviews();
  }, [id]);
  const [reportModal, setReportModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState("");
  const [showModal, setShowModal] = useState(false);
  const accessToken = JSON.parse(localStorage.getItem("data"));
  const chatConversation = useSelector((state) => state?.chat?.conversation);

  const handleImageSelect = (index) => {
    setCurrentIndex(index);
    setShowModal(true);
  };

  const fileImages = files?.filter((image) => image?.type !== "video");
  const handleBack = () => {
    dispatch(setSearchedProfilesList([]));
    navigate(-1);
  };

  const handleViewMore = async () => {
    setViewMoreLoading(true);
    await getProfileVideosData1(dispatch, userData?.usersWithCompleteProfile);
    setViewMoreLoading(false);
  };

  const getChat = async (user) => {
    if (accessToken) {
      let item = {
        oponent: {
          first_name: user?.first_name,
          id: user?.id,
          last_name: user?.last_name,
          profile_picture:
            userType === "tradesman"
              ? user?.tradesman?.profile_picture
              : userType === "homeowner"
              ? user?.homeowner?.profile_picture
              : user?.contractor?.profile_picture,
          userId:
            userType === "tradesman"
              ? user?.tradesman?.id
              : userType === "homeowner"
              ? user?.homeowner?.id
              : user?.contractor?.id,
        },
        oponentType:
          userType === "tradesman"
            ? "Gigster"
            : userType === "homeowner"
            ? "Homeowner"
            : "Contractor",
      };
      leaveConversation(chatConversation?.id);
      dispatch(setChatDetailMobile(true));
      const userData = JSON.parse(localStorage.getItem("data"));
      const payload = {
        senderID: userData?.id,
        receiverID:
          userType === "tradesman"
            ? user?.tradesman?.id
            : userType === "homeowner"
            ? user?.homeowner?.id
            : user?.contractor?.id,
        senderType: userData?.user_type,
        receiverType: userType,
        take: 10,
        skip: 0,
      };
      await loadConversation(payload, item, dispatch);
      navigate("/chat");
    } else {
      navigate("/welcome");
    }
  };
  const handleContractClick = () => {
    getContractValues({
      navigate,
      verification: userTypeData?.sendingAccountVerify,
      setVerificationModal: () => {},
      senderType: token?.user_type,
      receiverType: userTypeFromUrl,
      receiverTypeId: id,
      skills: skills,
    });
  };
  const handleVideoSelect = (index) => {
    setVideoCurrentIndex(index);
    setShowVideoModal(true);
  };

  return (
    <>
      {loading && (
        <div className="search-loader">
          <Loader />
        </div>
      )}
      {profile && (
        <div className="container d-flex flex-column align-items-center mt-5">
          <div
            className="mt-4 w-100 d-flex  align-items-center"
            onClick={handleBack}
            style={{ cursor: "pointer" }}
          >
            <img
              className="shadow-lg border rounded"
              src={backIcon}
              alt={backIcon}
            />
            <span className="ms-2">
              {props.intl.formatMessage({ id: "back" })}
            </span>
          </div>
          <div className="row border  border-sm-none rounded p-3 mb-4 mt-4 w-100">
            <div className="col-12 col-sm-12 col-md-2  d-flex flex-column align-items-center align-items-sm-center align-items-md-start justify-content-center">
              <div style={{ width: "100px", height: "100px" }}>
                <img
                  src={
                    type?.profile_picture === null ||
                    type?.profile_picture === ""
                      ? profileIcon
                      : `${process.env.REACT_APP_S3_BUCKET_URL}${type?.profile_picture}`
                  }
                  alt=""
                  className="gig-image "
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 d-flex flex-column  d-flex flex-column align-items-center align-items-sm-center align-items-md-start align-items-lg-start align-items-xl-start align-items-xxl-start">
              <span className="profile-username">
                {profile.first_name} {profile.last_name}
              </span>
              <span>
                <EnvironmentOutlined style={{ color: "#F77E0B" }} />
                <span className="trad-location">
                  {profile.city},{profile.state}
                </span>
              </span>
            </div>
            <div className=" col-12 col-sm-12 col-md-3  d-flex flex-column align-items-center align-items-sm-center align-items-md-start">
              <div
                className="trad-banner"
                style={
                  userType === "tradesman"
                    ? { backgroundColor: "#8ABB2A" }
                    : userType === "contractor"
                    ? { backgroundColor: "#F77E0B" }
                    : { backgroundColor: "#5E7AA7" }
                }
              >
                {userType === "tradesman" ? (
                  <span className="">Gigster</span>
                ) : (
                  <span style={{ textTransform: "capitalize" }}>
                    {userType}
                  </span>
                )}
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-3  d-flex flex-column align-items-center align-items-sm-center align-items-md-start bg-red">
              <div onClick={() => getChat(profile)} className="chat-button">
                <FormattedMessage id="chat" />
              </div>
              {userType !== "homeowner" && userType !== token?.user_type ? (
                <div className="contract-button" onClick={handleContractClick}>
                  <FormattedMessage id="contract" />
                </div>
              ) : null}
              <div
                className="report-profile shadow"
                onClick={() => setReportModal(true)}
              >
                <img
                  src={reportIcon}
                  style={{ width: "35px", marginRight: "5px" }}
                />
                <FormattedMessage id="report_profile" />
              </div>
            </div>
          </div>
          {userType !== "homeowner" && (
            <div className=" w-100 d-flex justify-content-between flex-wrap ">
              <div className="d-flex d-md-none d-sm-flex w-100 d-flex justify-content-center">
                <span className="profile-subheading mt-2 mb-2 ">Intro</span>
              </div>
              <div
                className="trad-intro shadow rounded p-4 d-flex flex-column  justify-content-start text-center text-sm-center text-md-start"
                style={{ height: "200px" }}
              >
                <div style={{ overflowY: "scroll" }}>
                  <span className="trad-intro-text d-none d-md-flex d-sm-none ">
                    {props.intl.formatMessage({ id: "intro" })}
                  </span>
                  <span style={{ wordWrap: "break-word" }}>
                    {" "}
                    {type?.intro_text}
                  </span>
                </div>
              </div>
              <div className="d-flex d-md-none d-sm-flex w-100 d-flex justify-content-center">
                <span className="profile-subheading mt-2 mb-2">
                  {props.intl.formatMessage({ id: "images" })}
                </span>
              </div>

              <div className="trad-intro shadow d-flex flex-column justify-content-start p-4 rounded">
                <div>
                  <span
                    className="trad-intro-text  d-none d-md-flex d-sm-none"
                    style={{ fontWeight: "bold" }}
                  >
                    {props.intl.formatMessage({ id: "images" })}
                  </span>
                </div>
                <div className="d-flex ">
                  <div
                    style={{
                      overflowX: "scroll",
                      display: "flex",
                      width: "100%",
                      marginTop: "20px",
                    }}
                  >
                    {files?.length > 0 &&
                      files?.map((image, index) => {
                        if (image.type === "video") {
                          return;
                        } else {
                          return (
                            <div className="ms-2 rounded">
                              <img
                                src={`${process.env.REACT_APP_S3_BUCKET_URL}${image.key}`}
                                alt=""
                                className=" rounded"
                                style={{
                                  width: "60px",
                                  height: "60px",
                                  objectFit: "cover",
                                }}
                                onClick={() => handleImageSelect(index)}
                              />
                            </div>
                          );
                        }
                      })}
                  </div>
                </div>
              </div>
            </div>
          )}
          {userType !== "homeowner" && (
            <div className="row  w-100 border rounded d-flex flex-column p-4  mt-4">
              <span className="mb-2 " style={{ fontWeight: "bold" }}>
                {" "}
                {props.intl.formatMessage({ id: "skills" })}
              </span>
              <div className="d-flex flex-wrap">
                {!isEmpty(type?.skills) &&
                  type.skills.map((skill) => {
                    return (
                      <>
                        {skill?.user_skill && (
                          <div className="skill-skill">
                            <span>{skill?.user_skill}</span>
                          </div>
                        )}
                      </>
                    );
                  })}
              </div>
            </div>
          )}
          <div className="border w-100 mt-3 rounded">
            <div
              className="mt-4 ms-4 fw-bold"
              style={{ fontFamily: "Poppins" }}
            >
              <FormattedMessage id="videos" />
            </div>
            <div className=" profile-videos-div mt-3">
              {!isEmpty(videos) &&
                videos.map((item, index) => {
                  return (
                    <div
                      className="profile-video-card border shadow"
                      key={item?.id}
                    >
                      <img className="play-icon" src={playIcon} alt="" />

                      <img
                        src={
                          item?.thumbnail
                            ? `${bucket_base_url}${item?.thumbnail}`
                            : thumbNail
                        }
                        alt=""
                        className="w-100 h-100"
                        style={{
                          objectFit: "cover",
                          borderRadius: "10px",
                        }}
                        onClick={() => handleVideoSelect(index)}
                      />
                    </div>
                  );
                })}
              {isEmpty(videos) && (
                <div
                  className="w-100 d-flex justify-content-center"
                  style={{ fontFamily: "Poppins" }}
                >
                  <span className="text-center">
                    {props.intl.formatMessage({ id: "novideos" })}
                  </span>
                </div>
              )}
            </div>
          </div>
          {userType !== "homeowner" && (
            <div className="border rounded w-100 h-auto p-3 mt-5">
              <div className="w-100 d-flex justify-content-between align-items-center mb-3">
                <div className=" d-flex align-items-center">
                  <span className="review-text">
                    {props.intl.formatMessage({ id: "reviews" })}
                  </span>
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{ fontFamily: "Poppins" }}
                >
                  <img src={star} />
                  <span className="ms-2">{type?.ratings}</span>
                </div>
              </div>
              {reviewsList.length === 0 && (
                <div className="d-flex justify-content-center">
                  {reviewLoading ? (
                    <Loader />
                  ) : (
                    <span>{props.intl.formatMessage({ id: "noreviews" })}</span>
                  )}
                </div>
              )}
              {!isEmpty(reviewsList) &&
                reviewsList?.map((review, index) => {
                  return (
                    <>
                      {review?.tradesman ? (
                        <div className="w-100 d-flex flex-column" key={index}>
                          <div className="w-100 d-flex justify-content-start align-items-center">
                            <div style={{ width: "60px" }}>
                              <div className="review-profile rounded-circle border ">
                                <img
                                  src={
                                    review?.tradesman?.profile_picture ===
                                      null ||
                                    review?.tradesman?.profile_picture === ""
                                      ? profileIcon
                                      : `${process.env.REACT_APP_S3_BUCKET_URL}${review?.tradesman?.profile_picture}`
                                  }
                                  className="img-fluid w-100 h-100 rounded-circle"
                                />
                              </div>
                            </div>
                            <div className="d-flex flex-column justify-content-between ms-3">
                              <div>
                                <span className="review-profile-name">
                                  {review.tradesman.first_name}{" "}
                                  {review.tradesman.last_name}
                                </span>
                              </div>
                              <div style={{ width: "100%" }}>
                                <span className="review-content">
                                  {review.comment}
                                </span>
                              </div>
                            </div>
                          </div>
                          <Divider style={{ margin: "15px 0" }} />
                        </div>
                      ) : review.contractor ? (
                        <div className="w-100 d-flex flex-column" key={index}>
                          <div className="w-100 d-flex justify-content-start align-items-center">
                            <div style={{ width: "60px" }}>
                              <div className="review-profile rounded-circle border ">
                                <img
                                  src={
                                    review?.contractor?.profile_picture ===
                                      null ||
                                    review?.contractor?.profile_picture === ""
                                      ? profileIcon
                                      : `${process.env.REACT_APP_S3_BUCKET_URL}${review?.contractor?.profile_picture}`
                                  }
                                  className="img-fluid w-100 h-100 rounded-circle"
                                />
                              </div>
                            </div>
                            <div className="d-flex flex-column justify-content-between w-100 ms-3">
                              <span className="review-profile-name">
                                {review.contractor.first_name}{" "}
                                {review.contractor.last_name}
                              </span>
                              <span className="review-content">
                                {review.comment}
                              </span>
                            </div>
                          </div>
                          <Divider style={{ margin: "15px 0" }} />
                        </div>
                      ) : review?.homeowner ? (
                        <div className="w-100 d-flex flex-column" key={index}>
                          <div className="w-100 d-flex justify-content-start align-items-center">
                            <div style={{ width: "60px" }}>
                              <div className="review-profile rounded-circle border ">
                                <img
                                  src={
                                    review?.homeowner?.profile_picture ===
                                      null ||
                                    review?.homeowner?.profile_picture === ""
                                      ? profileIcon
                                      : `${process.env.REACT_APP_S3_BUCKET_URL}${review?.homeowner?.profile_picture}`
                                  }
                                  className="img-fluid w-100 h-100 rounded-circle"
                                />
                              </div>
                            </div>
                            <div className="d-flex flex-column justify-content-between w-100 ms-3">
                              <span className="review-profile-name">
                                {review?.homeowner?.first_name}{" "}
                                {review?.homeowner?.last_name}
                              </span>
                              <span className="review-content">
                                {review.comment}
                              </span>
                            </div>
                          </div>
                          <Divider style={{ margin: "15px 0" }} />
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })}
            </div>
          )}
          <div className="d-flex w-100 mt-4 mb-4">
            <span
              style={{
                fontFamily: "Poppins",
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              {props.intl.formatMessage({ id: "others" })}
            </span>
          </div>
          <div className="row">
            <div className="row">
              {loading ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "500px" }}
                >
                  <Loader />
                </div>
              ) : (
                profileVideos?.length > 0 &&
                profileVideos.map((item) => {
                  if (
                    item?.availabilityRecord.includes("tradesman") &&
                    item?.tradesman
                  ) {
                    return (
                      <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3 mt-3 d-flex justify-content-center">
                        <VideoCard
                          videoSrc={`${process.env.REACT_APP_S3_BUCKET_URL}${item?.tradesman?.tradesman_files[0]?.key}`}
                          type={"tradesman"}
                          user={item?.tradesman}
                          profile={item}
                          adminSkills={skills}
                        />
                      </div>
                    );
                  } else if (
                    item?.availabilityRecord.includes("contractor") &&
                    item?.contractor
                  ) {
                    return (
                      <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3 mt-3 d-flex justify-content-center">
                        <VideoCard
                          videoSrc={`${process.env.REACT_APP_S3_BUCKET_URL}${item?.contractor?.contractor_files[0]?.key}`}
                          type={"contractor"}
                          user={item?.contractor}
                          profile={item}
                          adminSkills={skills}
                        />
                      </div>
                    );
                  }
                })
              )}
              <div
                className="d-flex justify-content-center align-items-center w-100 "
                style={{ height: "100px" }}
              >
                {viewMoreLoading ? (
                  <Loader />
                ) : (
                  profileVideos?.length + 1 < profileCount && (
                    <a className="LoadMore" onClick={handleViewMore}>
                      {props.intl.formatMessage({ id: "body.viewmore" })}
                    </a>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {files && showModal && (
        <ImageViewModal
          images={fileImages}
          index={currentIndex}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}

      <Footer />
      <ReportProfile
        setReportModal={setReportModal}
        reportModal={reportModal}
        userType={userType}
        profile={profile}
      />
      {showVideoModal && (
        <VideoPlayModal
          images={videos}
          index={videoCurrentIndex}
          showModal={showVideoModal}
          setShowModal={setShowVideoModal}
        />
      )}
    </>
  );
}

export default injectIntl(TradProfile);
