import { LoadingOutlined } from "@ant-design/icons";
import { Button, message, Spin } from "antd";
import axios from "axios";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import backIcon from "../../assets/images/backButton.svg";
import logo from "../../assets/images/gigmiLogo34.png";
import { base_url } from "../../constants";
import { injectIntl } from "react-intl";
import { sessionMaintain } from "../../api/sessionsApi/session";
function Otp(props) {
  const url_string = window.location.href;
  const url2 = new URL(url_string);
  const fromSignUP = url2.searchParams.get("flag");
  const navigate = useNavigate();
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [isLoading, setIsLoading] = useState(false);
  const userData = localStorage.getItem("userData");
  const user = JSON.parse(userData);
  const { otpType, username } = user;
  const inputRefs = useRef([]);
  const handleChange = (e, index) => {
    const updatedOtp = [...otp];
    const inputValue = e.target.value;
    if (!isNaN(inputValue)) {
      updatedOtp[index] = inputValue.substring(0, 1);
      setOtp(updatedOtp);
      if (index < inputRefs.current.length - 1 && inputValue.length === 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyPress = (e, index) => {
    if (e.key === "Backspace" && otp[index] === "" && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };
  const isConfirmButtonDisabled = otp.some((digit) => digit === "");
  const response = localStorage.getItem("apiResponse");
  const userId = JSON.parse(response);
  const otpValue = otp.join("");

  const handleOtpSubmit = async () => {
    setIsLoading(true);
    await axios
      .post(`${base_url}auth/verifyOTP`, {
        id: userId,
        otp: otpValue,
        type: false,
      })
      .then(async (response) => {
        localStorage.setItem("data", JSON.stringify(response.data));
        await sessionMaintain();
        message.success(`${props.intl.formatMessage({ id: "otp_success" })}`);
        message.success(
          `${props.intl.formatMessage({ id: "otp_send_success" })}`
        );
        setIsLoading(false);
        if (fromSignUP == true) {
          window.location.href = "/login";
        } else {
          window.location.href = "/";
        }
      })
      .catch((error) => {
        setIsLoading(false);
        const errorMessage = error.response.data.message;
        message.error(errorMessage);
      });
  };
  const handleResendOtp = async () => {
    const OTPSentTO = username;
    await axios
      .post(`${base_url}auth/forgotPassword/${OTPSentTO}`)
      .then((response) => {
        message.success(
          `${props.intl.formatMessage({ id: "otp_resend_success" })}`
        );
      })
      .catch((error) => {
        message.error(`${props.intl.formatMessage({ id: "cannot_send_otp" })}`);
      });
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 64,
        color: "#F77E0B ",
      }}
      spin
    />
  );

  return (
    <div className="view">
      <div className="row vh-100 d-flex justify-content-center">
        <div className="col-12 col-sm-12 col-md-6 welcome1-background d-flex flex-column justify-content-center align-items-center">
          <div
            style={{
              position: "absolute",
              top: "30px ",
              left: "40px",
              fontFamily: "Poppins",
            }}
            className="d-flex justify-content-center align-items-center"
          >
            <img
              src={backIcon}
              alt=""
              onClick={() => navigate(-1)}
              style={{ cursor: "pointer" }}
              className="shadow-lg border rounded"
            />
            <span className="text-black fs-4 m-1">
              {props.intl.formatMessage({ id: "back" })}
            </span>
          </div>
          <div
            className="w-100 d-flex d-sm-flex d-md-none d-lg-none justify-content-center"
            style={{ marginTop: "100px" }}
          >
            <img src={logo} className="w-25" />
          </div>

          <div
            className="bg-white  shadow-lg  p-4  mt-5 mb-5"
            style={{ borderRadius: "15px" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "32px",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <span className="signIn-text margin-left-text fw-bolder">
                {props.intl.formatMessage({ id: "verification_code" })}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: "12px",
                justifyContent: "center",
              }}
            >
              <span className="login-sub-text text-center">
                {props.intl.formatMessage({ id: "we_have_sent" })} {otpType}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "32px",
              }}
            >
              <span
                className=" text-center signIn-text1"
                style={{ wordWrap: "break-word", maxWidth: "100%" }}
              >
                {username}
              </span>
            </div>

            <div
              className="row"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "24px",
                }}
              >
                {otp.map((digit, index) => (
                  <input
                    style={{
                      width: "55px",
                      height: "55px",
                      paddingLeft: "20px",
                      marginLeft: "10px",
                      border: "1px solid #6B7380",
                      borderRadius: "5px",
                      color: "#6B7380",
                      fontSize: "24px",
                      fontWeight: "500",
                    }}
                    key={index}
                    type="number"
                    min={0}
                    max={9}
                    step={1}
                    inputMode="numeric"
                    pattern="[0-9]*"
                    value={digit}
                    onChange={(e) => handleChange(e, index)}
                    onKeyPress={(e) => handleKeyPress(e, index)}
                    ref={(ref) => (inputRefs.current[index] = ref)}
                  />
                ))}
              </div>
            </div>
            <div className="container" style={{ marginTop: "24px" }}>
              {isLoading ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Spin
                    // delay={5000}
                    spinning={isLoading}
                    size="larger"
                    indicator={antIcon}
                  />
                </div>
              ) : (
                <Button
                  style={{
                    width: "100%",
                    backgroundColor: "#F77E0B",
                    height: "56px",
                    color: "white",
                  }}
                  type="submit"
                  htmlType="submit"
                  disabled={isConfirmButtonDisabled}
                  onClick={handleOtpSubmit}
                >
                  {props.intl.formatMessage({ id: "verify" })}
                </Button>
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "12px",
              }}
            >
              <span>{"Didn’t receive OTP?"}</span>
              <span className="link">
                <a
                  href="#"
                  style={{
                    fontWeight: "bold",
                    marginLeft: 5,
                    cursor: "pointer",
                  }}
                  onClick={handleResendOtp}
                >
                  {props.intl.formatMessage({ id: "resend_otp" })}
                </a>
              </span>
            </div>
          </div>
        </div>
        <div className="col-6 d-none d-sm-none d-md-flex justify-content-center align-items-center welcome-background">
          <img src={logo} style={{ width: "300px", height: "170px" }} />
        </div>
      </div>
    </div>
  );
}
export default injectIntl(Otp);
