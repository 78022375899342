import React, { useState } from "react";
import "./style.css";
import { Divider, Modal } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import { deActivateAccount } from "../../api/bankApi/BankAccountList";
import { useNavigate } from "react-router";
const BankAccountCard = ({
  title,
  accNumber,
  active,
  userId,
  routingNumber,
  account_type,
  setLoading,
}) => {
  const [confirmModal, setConfirmModal] = useState(false);
  const navigate = useNavigate();
  const confirmAction = async () => {
    setConfirmModal(false);
    setLoading(true);
    const res = await deActivateAccount(userId);
    setLoading(false);
    if (res === "deleted") {
      navigate("/bank-info?path=add");
    }
  };

  return (
    <div className="bank-account-card">
      <div className="d-flex justify-content-between">
        <span>{title}</span>
        <button className="deactivate" onClick={() => setConfirmModal(true)}>
          <FormattedMessage id="deactivate" />
        </button>
      </div>
      <Divider />
      <div className="d-flex justify-content-between">
        <span>
          <FormattedMessage id="Account#" />
        </span>
        <span>**********{accNumber}</span>
      </div>
      <div className="d-flex justify-content-between">
        <span>
          <FormattedMessage id="Routing#" />
        </span>
        <span>{routingNumber}</span>
      </div>
      <div className="d-flex justify-content-between">
        <span>
          <FormattedMessage id="Account_Type" />
        </span>
        <span>{account_type}</span>
      </div>
      <Modal
        open={confirmModal}
        onCancel={() => setConfirmModal(false)}
        footer={null}
      >
        <div>
          <div className="disclaimer1 text-center mt-3">
            {active ? (
              <FormattedMessage id="confirm_disconnect1" />
            ) : (
              <FormattedMessage id="confirm_disconnect2" />
            )}
          </div>
          <div className="d-flex justify-content-center mt-5">
            <button
              style={{
                backgroundColor: "#F77E0B",
                color: "white",
                fontSize: "17px",
                fontFamily: "Poppins",
                border: "none",
                borderRadius: "3px",
              }}
              onClick={confirmAction}
            >
              {active ? (
                <FormattedMessage id="deactivate" />
              ) : (
                <FormattedMessage id="activate" />
              )}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default injectIntl(BankAccountCard);
