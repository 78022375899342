import React, { useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import "./BankInfo.css";
import Header from "../../components/Header/Header";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Button, Divider, Modal } from "antd";
import AddBank from "../../assets/images/AddBank.svg";
import Wallet from "../../assets/images/editBank.svg";
import { BackButton } from "../../components/General/BackButton";
import receiverIcon from "../../assets/images/payment1.svg";
import senderIcon from "../../assets/images/Payment.svg";
const PaymentOptions = (props) => {
  const navigate = useNavigate();
  const [bankModal, setBankModal] = useState(false);
  const [contractorModal, setContractorModal] = useState(false);
  const [paymentType, setPaymenType] = useState("");
  const fullUser = useSelector((state) => state?.common?.fullUser);
  const navigateTo = () => {
    if (paymentType === "send") {
      navigate("/add-hom-account");
    } else if (
      paymentType === "receive" &&
      fullUser?.receivingAccountVerify &&
      fullUser?.kyc
    ) {
      navigate("/bank-info");
    } else if (paymentType === "receive" && fullUser?.receivingAccountVerify) {
      navigate("/w9-form");
    } else {
      navigate("/get-payments");
    }
    setBankModal(false);
  };
  const handleReceivingAccount = () => {
    if (fullUser?.receivingBankAccountVerify) {
      setContractorModal(true);
    } else {
      setBankModal(true);
    }
    setPaymenType("receive");
  };
  return (
    <>
      <BackButton />
      <div className="container paymentscreen">
        <Header />
        <div className="paymentscreen-inner">
          <div className="d-flex justify-content-center align-items-center getting-payment-heading shadow border">
            <span>
              <FormattedMessage id="payments" />
            </span>
          </div>
          <div onClick={handleReceivingAccount} className="payment-option">
            <img src={receiverIcon} style={{ marginRight: "20px" }} />
            <FormattedMessage id="receiving_accounts" />
          </div>
          <Divider />
          <div
            onClick={() => {
              if (fullUser?.sendingAccountVerify) {
                setContractorModal(true);
              } else {
                setBankModal(true);
              }

              setPaymenType("send");
            }}
            className="payment-option"
          >
            <img src={senderIcon} style={{ marginRight: "12px" }} />
            <FormattedMessage id="send_payment_accounts" />
          </div>
          <Divider />
          {fullUser?.receivingBankAccountVerify ||
          fullUser?.sendingAccountVerify ? (
            <div className="d-flex justify-content-center">
              <Button
                className="button-modal w-50"
                onClick={() =>
                  navigate("/get-payments", { state: { path: "edit_email" } })
                }
              >
                <FormattedMessage id="change_account_email" />
              </Button>
            </div>
          ) : null}
        </div>
        <Modal
          open={bankModal}
          footer={null}
          onCancel={() => setBankModal(false)}
        >
          <div className="d-flex justify-content-around align-items-center">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <span className="fs-3 fw-bold mb-3">
                {props.intl.formatMessage({ id: "Select" })}
              </span>
              <img
                src={AddBank}
                className="bank-modal-image"
                onClick={() => navigateTo()}
              />
              <span className="bank-modal-text">
                {props.intl.formatMessage({ id: "add_bank_info" })}
              </span>
            </div>
          </div>
        </Modal>
        <Modal
          open={contractorModal}
          onCancel={() => setContractorModal(false)}
          footer={null}
        >
          <div className="d-flex justify-content-around align-items-center">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <span className="fs-3 fw-bold mb-3">
                {props.intl.formatMessage({ id: "select" })}
              </span>
              <img
                src={Wallet}
                className="bank-modal-image"
                onClick={() => {
                  setContractorModal(false);
                  if (paymentType === "receive") {
                    navigate("/bank-accounts?path=add");
                  } else {
                    navigate("/add-hom-account?path=Edit");
                  }
                }}
              />
              <span className="bank-modal-text">
                {props.intl.formatMessage({ id: "edit_bank_account" })}
              </span>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};
export default injectIntl(PaymentOptions);
