import { message } from "antd";
import { Axios } from "../../utils/axiosMiddleware";

export const PaymentScreenTwoApi = async (
  body,
  setShowModal,
  navigate,
  path
) => {
  try {
    await Axios.post(`gigwage/kyc`, body);
    path == "debitcard" ? setShowModal(true) : navigate("/bank-info");
  } catch (e) {
    if (e.response?.data[0]) {
      message.error(`${e.response?.data[0]}`);
    } else if (e.message === "Network Error") {
      message.error(e.message);
    } else {
      message.error(e.response.data.message);
    }
  }
};
