import { Button, DatePicker, Form, Input, Modal, Select } from "antd";
import React, { useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import Header from "../../components/Header/Header";
import "./BankInfo.css";
import {
  formatDate,
  formatPhoneNumber,
  formatSocialNumber,
} from "../../utils/Helper";
import { useSelector } from "react-redux";
import { PaymentScreenTwoApi } from "../../api/bankApi/PaymentScreenTwoApi";
import Loader from "../../components/GeneralComponents";
import CardInput from "../../components/BankInfo/CreditCard";
import { useLocation, useNavigate } from "react-router";
import { BackButton } from "../../components/General/BackButton";
import usStates1 from "../../assets/UsStates";
import usCitiesWithCode from "../../assets/CitiesWithStateCodes";
const PaymentScreenTwo = (props) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const path = queryParams.get("path");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [form] = Form.useForm();
  const fullUser = useSelector((state) => state?.common?.fullUser);
  const userData = JSON.parse(localStorage.getItem("data"));
  const navigate = useNavigate();
  const Cities = [];
  usCitiesWithCode.map((city) => {
    if (city.state === state) {
      Cities.push({
        label: city.city,
        value: city.city,
      });
    }
  });
  const handleState = (e) => {
    setCity("");
    form.setFieldValue({ city: city });
    form.resetFields(["city"]);
    setState(e);
  };
  const disabledDate = (current) => {
    const minAllowedDate = new Date();
    minAllowedDate.setFullYear(minAllowedDate.getFullYear() - 18);
    return current && current >= minAllowedDate;
  };

  const handleSubmit = async (values) => {
    const DOB = formatDate(values.dob);
    const SN = formatSocialNumber(values.socialSecurity);
    const PN = formatPhoneNumber(values.phoneNumber);
    const body = {
      social_security: SN,
      phone_number: PN,
      birthdate: DOB,
      address1: values?.address,
      city: values?.city,
      zip: values?.postalCode.replace(/\D/g, ""),
      state: values?.state,
      userId: fullUser?.id,
    };
    setLoading(true);
    await PaymentScreenTwoApi(body, setShowModal, navigate, path);
    setLoading(false);
  };
  return (
    <>
      {loading && (
        <div className="search-loader">
          <Loader />
        </div>
      )}
      <BackButton />
      <div className="container paymentscreen">
        <Header />
        <div className="paymentscreen-inner">
          <div className="d-flex justify-content-center align-items-center getting-payment-heading shadow border">
            <span>
              <FormattedMessage id="w9-form" />
            </span>
          </div>
          <Form
            onFinish={handleSubmit}
            form={form}
            layout="vertical"
            initialValues={{
              socialSecurity: "",
              phoneNumber: "",
              dob: "",
              postalCode: "",
              state: "",
              city: "",
              address: "",
            }}
          >
            <Form.Item
              name="socialSecurity"
              label={props.intl.formatMessage({ id: "social_security" })}
              rules={[
                {
                  required: true,
                  message: `${props.intl.formatMessage({ id: "required" })}`,
                },
                {
                  pattern: /^[0-9 ]/,
                  message: `${props.intl.formatMessage({
                    id: "valid_social",
                  })}`,
                },
              ]}
            >
              <Input
                placeholder="123456789"
                maxLength={9}
                className="input-field"
              />
            </Form.Item>
            <Form.Item
              name="phoneNumber"
              label={props.intl.formatMessage({ id: "phone_number" })}
              rules={[
                {
                  required: true,
                  message: `${props.intl.formatMessage({ id: "required" })}`,
                },
                {
                  pattern: /^[0-9 ]/,
                  message: `${props.intl.formatMessage({ id: "valid_phone" })}`,
                },
              ]}
            >
              <Input
                placeholder="1234567890"
                maxLength={10}
                className="input-field"
              />
            </Form.Item>

            <div className="row">
              <div className="col-6">
                <Form.Item
                  name="dob"
                  label={props.intl.formatMessage({ id: "D_O_B" })}
                  rules={[
                    {
                      required: true,
                      message: `${props.intl.formatMessage({
                        id: "required",
                      })}`,
                    },
                  ]}
                >
                  <DatePicker
                    disabledDate={disabledDate}
                    placeholder={props.intl.formatMessage({
                      id: "select_your_dob",
                    })}
                    className="input-field w-100"
                  />
                </Form.Item>
              </div>
              <div className="col-6">
                <Form.Item
                  name="postalCode"
                  label={props.intl.formatMessage({ id: "postal_code" })}
                  rules={[
                    {
                      required: true,
                      message: `${props.intl.formatMessage({
                        id: "required",
                      })}`,
                    },
                    {
                      pattern: /^[0-9 ]/,
                      message: `${props.intl.formatMessage({
                        id: "valid_post_code",
                      })}`,
                    },
                  ]}
                >
                  <Input
                    placeholder={props.intl.formatMessage({
                      id: "postal_code",
                    })}
                    className="input-field"
                  />
                </Form.Item>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <Form.Item
                  name="state"
                  label={props.intl.formatMessage({ id: "state" })}
                  rules={[
                    {
                      required: true,
                      message: `${props.intl.formatMessage({
                        id: "state_required",
                      })}`,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={{
                      width: "100%",
                    }}
                    placeholder={props.intl.formatMessage({ id: "state" })}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={usStates1}
                    onSelect={(e) => handleState(e)}
                  />
                </Form.Item>
              </div>
              <div className="col-6">
                <Form.Item
                  name="city"
                  label={props.intl.formatMessage({ id: "city" })}
                  rules={[
                    {
                      required: true,
                      message: `${props.intl.formatMessage({
                        id: "city_required",
                      })}`,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={{
                      width: "100%",
                    }}
                    placeholder={props.intl.formatMessage({ id: "state" })}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={Cities}
                  />
                </Form.Item>
              </div>
            </div>

            <Form.Item
              name="address"
              label={props.intl.formatMessage({ id: "address" })}
              rules={[
                {
                  required: true,
                  message: `${props.intl.formatMessage({ id: "required" })}`,
                },
              ]}
            >
              <Input
                className="input-field"
                placeholder={props.intl.formatMessage({ id: "address" })}
              />
            </Form.Item>
            <div className="d-flex justify-content-end">
              <Form.Item>
                <Button
                  type="submit"
                  htmlType="submit"
                  className="submit-button"
                >
                  <FormattedMessage id="submit" />
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
        <Modal
          open={showModal}
          footer={null}
          onCancel={() => setShowModal(false)}
          destroyOnClose={true}
        >
          <div className="d-flex justify-content-center align-items-center getting-payment-modal-heading">
            <span>
              <FormattedMessage id="debit_card" />
            </span>
          </div>
          <CardInput
            tradesmanId={userData?.id}
            setLoading={setLoading}
            setShowModal={setShowModal}
          />
        </Modal>
      </div>
    </>
  );
};
export default injectIntl(PaymentScreenTwo);
