import { Button, Modal, Progress } from "antd";
import React from "react";
import AppStore from "../../assets/images/AppStore.png";
import GooglePlay from "../../assets/images/GooglePlay.png";
import { injectIntl } from "react-intl";
import profileIcon from "../../assets/images/profileIcon.png";
import { useNavigate } from "react-router";

const CompleteProfileModal = ({
  showModal,
  setShowModal,
  currentUser,
  ...props
}) => {
  const isAuthenticated = JSON.parse(localStorage.getItem("data"));
  const navigate = useNavigate();
  return (
    <div>
      {" "}
      <Modal
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={false}
        width={400}
      >
        <div>
          <div
            style={{ position: "relative", height: "150px" }}
            className="d-flex justify-content-center"
          >
            <Progress
              type="circle"
              percent={currentUser?.health}
              strokeColor="#F77E0B"
              showInfo={false}
              style={{ position: "absolute" }}
            />
            <div
              style={{
                width: "105px",
                height: "105px",
                marginTop: "7px",
              }}
            >
              <img
                src={
                  currentUser?.profile_picture === null ||
                  currentUser?.profile_picture === ""
                    ? profileIcon
                    : `${process.env.REACT_APP_S3_BUCKET_URL}${currentUser?.profile_picture}`
                }
                className="img-fluid rounded-circle"
              />
            </div>
            <div
              style={{
                position: "absolute",
                marginTop: "80px",
                marginLeft: "180px",
              }}
            >
              <span className="health-show">{currentUser?.health}%</span>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <span className="username-text text-center">
              {currentUser?.first_name} {currentUser?.last_name}
            </span>
          </div>
          <div className="d-flex justify-content-center">
            <div
              className="type-banner mt-4 "
              style={
                isAuthenticated?.user_type === "tradesman"
                  ? { backgroundColor: "#8ABB2A" }
                  : { backgroundColor: "#F77E0B" }
              }
            >
              <span>
                {isAuthenticated?.user_type === "tradesman"
                  ? "Gigster"
                  : isAuthenticated?.user_type?.charAt(0)?.toUpperCase() +
                    isAuthenticated?.user_type?.slice(1)}
              </span>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-3">
            <Button
              onClick={() => navigate("/update-user")}
              style={{
                backgroundColor: "#F77E0B",
                height: "56px",
                width: "200px",
                color: "white",
                fontFamily: "Poppins",
                fontWeight: "bold",
              }}
            >
              {props.intl.formatMessage({ id: "body.complete" })}
            </Button>
          </div>
          <div className="mt-2">
            <div className="text-center">
              <p
                style={{
                  fontFamily: "Poppins",
                  fontSize: "17px",
                  fontWeight: "600",
                }}
              >
                {props.intl.formatMessage({ id: "clickAway.joinus" })}
              </p>
            </div>
            <div
              className="d-flex justify-content-center"
              style={{
                width: "100%",
                gap: "24px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <a
                href="https://play.google.com/store/apps/details?id=com.gigmi"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={GooglePlay}
                  alt="Google Play"
                  style={{ width: "120px" }}
                />
              </a>

              <a
                href="https://apps.apple.com/us/app/gigmi-contractor-revolution/id1604625082"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={AppStore}
                  alt="App Store"
                  style={{ width: "120px" }}
                />
              </a>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default injectIntl(CompleteProfileModal);
