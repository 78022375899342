import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BannerCards from "../../components/BannerCards/BannerCards";
import Footer from "../../components/Footer/Footer";
import Loader from "../../components/GeneralComponents";
import ClickAway from "../../components/Header/ClickAway";
import Header from "../../components/Header/Header";
import { VideoCard } from "../../components/VideoCard/VideoCard";
import AppStore from "../../assets/images/AppStore.png";
import GooglePlay from "../../assets/images/GooglePlay.png";
import { useNavigate } from "react-router";
import {
  getProfileVideosData,
  getProfileVideosData1,
  getStories,
  userLikesList,
} from "./Service";
import { FormattedMessage, injectIntl } from "react-intl";
import { SideTabNavigator } from "../../components/SideTabNavigator/SideTabNavigator";
import { WelcomeToGigmi } from "../../components/WelcomeToGigmi/WelcomeToGigmi";
import { Stories } from "../../components/Stories/Stories";
import { socket } from "../../sockets/SocketConnection";
import { setRedDot, setSocketConnection } from "../../actions/RedDotActions";
import { getContractsRequest } from "../../api/contractsApi/getContracts";
import { chatListing } from "../../api/chatApi/getChatListing";
import Disclaimer from "../../components/BankInfo/Disclaimer";
import { onMessage } from "firebase/messaging";
import { messaging } from "../../utils/firebase";
import { SwitchUser } from "../../components/Header/service";
import CompleteProfileModal from "../../components/UpdateProfile/CompleteProfileModal";
import { SubscriptionCard } from "../../components/Subscription/SubscriptionCard";
import { setUnsubscribedUser } from "../../actions/CommonActions";
import { isEmpty } from "lodash";
import { GetAdminSkills } from "../../api/contractsApi/getSkillsApi";
import { SkeltonCard } from "../../components/VideoCard/SkeltonCard";
import { Button } from "antd";

const Home = (props) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(true);
  const [viewMoreLoading, setViewMoreLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [joinUs, setJoinUs] = useState(false);
  const [notificationModal, setNotificationModal] = useState(false);
  const [switchModal, setSwitchModal] = useState(false);
  const [notificationBody, setNotificationBody] = useState(null);
  const [notificationType, setNotificationType] = useState(null);
  const [moveNow, setMoveNow] = useState("");
  const [switchAccount, setSwitchAccount] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState(null);
  const [adminSkills, setAdminSkills] = useState([]);
  const dispatch = useDispatch();
  const profileVideos = useSelector(
    (state) => state?.common?.profileVideosList?.data?.users
  );
  const profileCount = useSelector(
    (state) => state?.common?.profileVideosList?.data?.totalUsers
  );
  const userData = useSelector((state) => state?.common?.userData?.data);
  const fullUser = useSelector((state) => state?.common?.fullUser);
  const currentUser = useSelector((state) => state?.common?.newUser?.data);
  const isSocketConnected = useSelector(
    (state) => state?.RedDotReducer?.isSocketConnected
  );
  const userSubscribed = useSelector(
    (state) => state?.common?.unSubscribedUser
  );

  const searchedUsers = useSelector(
    (state) => state?.common?.searchedProfileList
  );
  const [loading, setLoading] = useState(false);
  const isAuthenticated = JSON.parse(localStorage.getItem("data"));
  const modalshow = localStorage.getItem("youtube");
  const getSkills = async () => {
    const res = await GetAdminSkills();
    setAdminSkills(res);
  };

  useEffect(() => {
    getSkills();
  }, []);
  useEffect(() => {
    if (!modalshow) {
      setJoinUs(true);
    }
  }, []);

  useEffect(() => {
    onMessage(messaging, (payload) => {
      // to display the toast with modal un comment below line
      // message.success(payload.notification.body);
      handleNotification(payload);
    });
    const fetchData = async () => {
      setLoading(true);
      await getProfileVideosData(dispatch, userData?.usersWithCompleteProfile);
      await getStories(dispatch);
      if (isAuthenticated) {
        await userLikesList(dispatch);
      }
      setLoading(false);
    };
    fetchData();
  }, [userData?.usersWithCompleteProfile]);

  const handleNotification = (remoteMessage) => {
    setNotificationBody(remoteMessage?.notification.body);
    setNotificationTitle(remoteMessage?.notification.title);
    if (remoteMessage) {
      if (remoteMessage?.data?.type !== "profile") {
        if (remoteMessage?.data?.type === isAuthenticated?.user_type) {
          if (remoteMessage?.data?.screenLocation === "ChatTab") {
            setNotificationModal(true);
            setMoveNow("/chat");
          } else if (remoteMessage?.data?.screenLocation === "gigs") {
            setNotificationModal(true);
            setMoveNow("/feeds");
          } else {
            setNotificationModal(true);
            setMoveNow(
              `/contract-details?id=${remoteMessage?.data?.screenLocation}`
            );
          }
          setNotificationType(remoteMessage?.data?.type);
        } else {
          setSwitchAccount(true);
          setSwitchModal(true);
          setNotificationType(remoteMessage?.data?.type);
        }
      } else if (remoteMessage?.data?.type === "subscription") {
        setNotificationModal(true);
        setNotificationType(remoteMessage?.data?.type);
      } else {
        let stringsReceived = remoteMessage?.data?.screenLocation?.split("_");
        setNotificationModal(true);
        setNotificationType(remoteMessage?.data?.type);
        setMoveNow(
          `/trads-profile?user-id=${stringsReceived[4]}&id=${stringsReceived[0]}&type=${stringsReceived[1]}`
        );
      }
    }
  };

  const handleViewMore = async () => {
    setViewMoreLoading(true);
    await getProfileVideosData1(dispatch, userData?.usersWithCompleteProfile);
    setViewMoreLoading(false);
  };

  useEffect(() => {
    if (socket) {
      dispatch(setSocketConnection(socket?.connected));
    }
  }, [socket?.connected]);

  useEffect(() => {
    if (
      isSocketConnected &&
      isAuthenticated?.user_type &&
      isAuthenticated?.id
    ) {
      socket.emit("leaveNotification", {
        user_id: isAuthenticated?.id,
        user_type: isAuthenticated?.user_type,
      });
      socket.off("leaveNotification");
      socket.off("red_dot");
      socket.emit("joinNotification", {
        user_id: isAuthenticated?.id,
        user_type: isAuthenticated?.user_type,
      });
      socket.on("red_dot", (data) => {
        if (
          data?.red_dot?.isContractSeen === false &&
          window.location?.pathname === "/contract-lists"
        ) {
          getContracts();
        }
        if (
          data?.red_dot?.isMessageSeen &&
          window.location?.pathname === "/chat"
        ) {
          getChats();
        }
        dispatch(
          setRedDot({
            ...data?.red_dot,
            contract_status: data?.contract_status,
          })
        );
      });
    }
  }, [isSocketConnected]);

  const getContracts = async () => {
    await getContractsRequest(dispatch);
  };

  const getChats = async () => {
    const take = 20;
    const skip = 0;
    await chatListing(take, skip, dispatch);
  };
  const cardArray = Array.from({ length: 8 });
  return (
    <div style={{ overflow: "auto", maxHeight: "100vh" }}>
      {/* <YoutubePlayer /> */}
      {searchLoading && (
        <div className="search-loader">
          <Loader />
        </div>
      )}
      <Header
        searchLoading={searchLoading}
        setSearchLoading={setSearchLoading}
      />
      <SideTabNavigator fullUser={fullUser} />
      <div className="container">
        <div
          className="d-flex d-sm-flex d-md-none"
          style={{
            width: "100%",
            gap: "18px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <a
            href="https://play.google.com/store/apps/details?id=com.gigmi"
            target="_blank"
            rel="noreferrer"
          >
            <img src={GooglePlay} alt="Google Play" className="playstoreIcon" />
          </a>

          <a
            href="https://apps.apple.com/us/app/gigmi-contractor-revolution/id1604625082"
            target="_blank"
            rel="noreferrer"
          >
            <img src={AppStore} alt="App Store" className="playstoreIcon" />
          </a>
        </div>
      </div>
      <Stories />
      <div className="container">
        <div className="row">
          {searchedUsers && searchedUsers.length > 0 ? (
            loading ? (
              <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3 mt-3 d-flex justify-content-center ">
                <SkeltonCard />
              </div>
            ) : (
              searchedUsers?.length > 0 &&
              searchedUsers.map((item) => {
                return (
                  <>
                    {item?.contractor && (
                      <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3 mt-3 d-flex justify-content-center ">
                        <VideoCard
                          videoSrc={`${process.env.REACT_APP_S3_BUCKET_URL}${item?.contractor?.contractor_files[0]?.key}`}
                          type="contractor"
                          user={item.contractor}
                          profile={item}
                          adminSkills={adminSkills}
                        />
                      </div>
                    )}
                    {item?.tradesman && (
                      <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3 mt-3 d-flex justify-content-center ">
                        <VideoCard
                          videoSrc={`${process.env.REACT_APP_S3_BUCKET_URL}${item?.tradesman?.tradesman_files[0]?.key}`}
                          type="tradesman"
                          user={item.tradesman}
                          profile={item}
                          adminSkills={adminSkills}
                        />
                      </div>
                    )}
                  </>
                );
              })
            )
          ) : loading ? (
            // <Loader />
            cardArray.map((index) => (
              <div
                key={index}
                className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3 mt-3 d-flex justify-content-center "
              >
                <SkeltonCard />
              </div>
            ))
          ) : (
            profileVideos?.length > 0 &&
            profileVideos.map((item) => {
              return (
                <>
                  {isAuthenticated &&
                  isAuthenticated?.user_type === "contractor" ? (
                    item?.availabilityRecord.includes("tradesman") &&
                    item?.tradesman ? (
                      <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3 mt-3 d-flex justify-content-center">
                        <VideoCard
                          videoSrc={`${process.env.REACT_APP_S3_BUCKET_URL}${item?.tradesman?.tradesman_files[0]?.key}`}
                          type={"tradesman"}
                          user={item?.tradesman}
                          profile={item}
                          adminSkills={adminSkills}
                        />
                      </div>
                    ) : (
                      <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3 mt-3 d-flex justify-content-center">
                        <VideoCard
                          videoSrc={`${process.env.REACT_APP_S3_BUCKET_URL}${item?.contractor?.contractor_files[0]?.key}`}
                          type={"contractor"}
                          user={item?.contractor}
                          profile={item}
                          adminSkills={adminSkills}
                        />
                      </div>
                    )
                  ) : (
                    <></>
                  )}
                  {isAuthenticated &&
                  isAuthenticated?.user_type === "tradesman" ? (
                    item?.availabilityRecord.includes("contractor") &&
                    item?.contractor ? (
                      <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3 mt-3 d-flex justify-content-center">
                        <VideoCard
                          videoSrc={`${process.env.REACT_APP_S3_BUCKET_URL}${item?.contractor?.contractor_files[0]?.key}`}
                          type={"contractor"}
                          user={item?.contractor}
                          profile={item}
                          adminSkills={adminSkills}
                        />
                      </div>
                    ) : (
                      <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3 mt-3 d-flex justify-content-center">
                        <VideoCard
                          videoSrc={`${process.env.REACT_APP_S3_BUCKET_URL}${item?.tradesman?.tradesman_files[0]?.key}`}
                          type={"tradesman"}
                          user={item?.tradesman}
                          profile={item}
                          adminSkills={adminSkills}
                        />
                      </div>
                    )
                  ) : isAuthenticated &&
                    isAuthenticated?.user_type === "homeowner" ? (
                    item?.availabilityRecord.includes("contractor") &&
                    item?.contractor ? (
                      <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3 mt-3 d-flex justify-content-center">
                        <VideoCard
                          videoSrc={`${process.env.REACT_APP_S3_BUCKET_URL}${item?.contractor?.contractor_files[0]?.key}`}
                          type={"contractor"}
                          user={item?.contractor}
                          profile={item}
                          adminSkills={adminSkills}
                        />
                      </div>
                    ) : (
                      <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3 mt-3 d-flex justify-content-center">
                        <VideoCard
                          videoSrc={`${process.env.REACT_APP_S3_BUCKET_URL}${item?.tradesman?.tradesman_files[0]?.key}`}
                          type={"tradesman"}
                          user={item?.tradesman}
                          profile={item}
                          adminSkills={adminSkills}
                        />
                      </div>
                    )
                  ) : (
                    <></>
                  )}
                  {!isAuthenticated && (
                    <>
                      {item?.availabilityRecord?.includes("tradesman") &&
                      item?.tradesman ? (
                        <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3 mt-3 d-flex justify-content-center">
                          <VideoCard
                            videoSrc={`${process.env.REACT_APP_S3_BUCKET_URL}${item?.tradesman?.tradesman_files[0]?.key}`}
                            type={"tradesman"}
                            user={item?.tradesman}
                            profile={item}
                            adminSkills={adminSkills}
                          />
                        </div>
                      ) : (
                        <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3 mt-3 d-flex justify-content-center">
                          <VideoCard
                            videoSrc={`${process.env.REACT_APP_S3_BUCKET_URL}${item?.contractor?.contractor_files[0]?.key}`}
                            type={"contractor"}
                            user={item?.contractor}
                            profile={item}
                            adminSkills={adminSkills}
                          />
                        </div>
                      )}
                    </>
                  )}
                </>
              );
            })
          )}
          {searchedUsers && searchedUsers.length === 0 && (
            <div
              className="d-flex justify-content-center align-items-center w-100 "
              style={{ height: "100px" }}
            >
              {profileVideos?.length + 1 < profileCount && (
                <Button
                  style={{
                    backgroundColor: "#f77e0b",
                    fontWeight: "bold",
                    color: "white",
                  }}
                  onClick={handleViewMore}
                  loading={viewMoreLoading}
                  size="large"
                >
                  {props.intl.formatMessage({ id: "body.viewmore" })}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
      <WelcomeToGigmi />
      <BannerCards />

      <ClickAway />
      <SubscriptionCard />
      <Footer />
      {isAuthenticated && currentUser?.health < 100 && (
        <CompleteProfileModal
          showModal={showModal}
          setShowModal={setShowModal}
          currentUser={currentUser}
        />
      )}
      {/* {!isAuthenticated && joinUs && (
        <JoinUs joinUs={joinUs} setJoinUs={setJoinUs} />
      )} */}
      <Disclaimer
        showModal={notificationModal}
        setShowModal={() => setNotificationModal(false)}
        title={<FormattedMessage id="Notification Received!" />}
        body={
          notificationBody ?? <FormattedMessage id="something_went_wrong" />
        }
        cancelText={<FormattedMessage id="cancel" />}
        confirmText={
          notificationType === "subscription" ? (
            ""
          ) : (
            <FormattedMessage id="View" />
          )
        }
        handleCancel={() => setNotificationModal(false)}
        handleClick={async () => {
          if (switchAccount) {
            await SwitchUser(notificationType);
            setNotificationModal(false);
            navigate(moveNow);
          } else {
            setNotificationModal(false);
            navigate(moveNow);
          }
        }}
      />
      <Disclaimer
        showModal={switchModal}
        setShowModal={() => setNotificationModal(false)}
        title={notificationTitle}
        body={
          notificationBody ?? <FormattedMessage id="something_went_wrong" />
        }
        cancelText={
          notificationType === "subscription" ? (
            <FormattedMessage id="ok" />
          ) : (
            <FormattedMessage id="cancel" />
          )
        }
        confirmText={
          notificationType === "subscription" ? (
            ""
          ) : (
            <FormattedMessage id="Switch" />
          )
        }
        handleCancel={() => setSwitchModal(false)}
        handleClick={async () => {
          await SwitchUser(notificationType);
        }}
      />
      <Disclaimer
        showModal={userSubscribed}
        title={<FormattedMessage id="unsubscribed_user" />}
        body={<FormattedMessage id="unsubscribed_user_text" />}
        cancelText={<FormattedMessage id="ok" />}
        handleCancel={() => dispatch(setUnsubscribedUser(false))}
      />
    </div>
  );
};

export default injectIntl(Home);
