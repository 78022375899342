import React, { useState } from "react";
import profileIcon from "../../assets/images/profileIcon.png";
import dolLar from "../../assets/images/dollar.svg";
import skillImage from "../../assets/images/skill.svg";
import locationPoint from "../../assets/images/locationPoint.svg";
import threeDot from "../../assets/images/threeDotsOrange.svg";
import likefill from "../../assets/images/like-fill.svg";
import like from "../../assets/images/like.svg";
import comment from "../../assets/images/comment.svg";
import { bucket_base_url } from "../../constants";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dropdown } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { isEmpty } from "lodash";
import {
  deletePost,
  likePost,
  unlikePost,
} from "../../api/Feeds/GetAllPostRequest";
import { useNavigate } from "react-router";
import { extractLatLng, truncateString } from "../../utils/Helper";
import { CommentSection } from "./CommentSection";
import { formatDistanceToNow, parseISO } from "date-fns";
import ImageViewModal from "../UserProfile/ImageViewModal";
import { FormattedMessage, injectIntl } from "react-intl";
import CompleteProfileModal from "../UpdateProfile/CompleteProfileModal";
const FeedsCard = ({
  ProfilePic,
  TimeAgo,
  firstName,
  lastName,
  postText,
  postImage,
  flatRate,
  skill,
  intestedCount,
  commentsCount,
  item,
  index,
  refresh,
  detail,
  handleCommentIconPress,
  comments,
  likes,
  setComment,
  handleCommentPress,
  commentdetailsCount,
  commentValue,
  ...props
}) => {
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("data"));
  const userTypeData = useSelector((state) => state?.common?.newUser?.data);
  const FeedsList = useSelector((state) => state?.feeds?.FeedsListing);
  const dispatch = useDispatch();
  const [isLike, setLike] = useState(
    item?.postInterested?.length > 0 ? true : false
  );
  const [likeCount, setLikeCount] = useState(intestedCount);
  const [isBlinking, setBlinking] = useState(false);
  const [tab, setTab] = useState("");
  const [showImage, setShowImage] = useState(false);
  const [intrestedId, setInterestedId] = useState(
    !isEmpty(item?.postInterested) ? item?.postInterested[0]?.id : null
  );
  const [showCompleteProfile, setShowCompleteProfile] = useState(false);
  const items = [
    {
      label: `${props.intl.formatMessage({ id: "edit_post" })}`,
      key: "edit",
      icon: <EditOutlined />,
    },
    {
      label: `${props.intl.formatMessage({ id: "delete_post" })}`,
      key: "delete",
      icon: <DeleteOutlined />,
    },
  ];
  const handleMenuClick = async (e) => {
    if (e.key === "delete") {
      await deletePost(item?.id, index, FeedsList, dispatch);
      refresh();
    } else {
      navigate("/create-post", {
        state: {
          path: "edit",
          data: item,
          onEdit: true,
          index,
        },
      });
    }
  };
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };
  const handleLikeDislike = async () => {
    if (userTypeData?.health < 100) {
      setShowCompleteProfile(true);
      return;
    }
    setBlinking(true);
    if (userData?.user_type === "tradesman") {
      if (isLike) {
        await unlikePost(intrestedId, item?.id, index);
        setLike(!isLike);
        setLikeCount((prev) => prev - 1);
      } else {
        const body = {
          postId: item?.id,
          tradesmanId: userTypeData?.id,
          index: index,
        };
        const res = await likePost(body);
        setInterestedId(res);
        setLike(!isLike);
        setLikeCount((prev) => prev + 1);
      }
    }
    setBlinking(false);
  };
  const seeDetail = () => {
    navigate("/post-details", {
      state: {
        data: item,
        index: index,
      },
    });
  };
  const DaysAgo = () => {
    const parsedTimestamp = parseISO(TimeAgo);
    const timeAgo = formatDistanceToNow(parsedTimestamp, { addSuffix: true });

    return timeAgo;
  };
  const coordinates = extractLatLng(item?.latt_long_loc_name);
  const handleOpenGoogleMaps = () => {
    const googleMapsUrl = `https://www.google.com/maps?q=${coordinates?.lat},${coordinates?.lng}`;
    window.open(googleMapsUrl, "_blank");
  };
  const viewImage = {
    key: postImage,
  };
  return (
    <div className="shadow border mb-5 mt-5" style={{ borderRadius: "5px" }}>
      <div className="d-flex align-items-center justify-content-around">
        <div className="avatar-container">
          <img
            src={
              ProfilePic === null || ProfilePic === ""
                ? `${profileIcon}`
                : `${bucket_base_url}${ProfilePic}`
            }
            style={{ objectFit: "cover" }}
            className="avatar-image"
          />
        </div>
        <div className="d-flex justify-content-between w-75">
          <div className="d-flex flex-column ">
            <span className="feeds-name">
              {firstName} {lastName}
            </span>
            <span className="feeds-name-subheading">
              Contractor, {DaysAgo()}
            </span>
          </div>
          <div>
            {item?.contractor?.id === userTypeData?.id && (
              <Dropdown
                menu={menuProps}
                placement="bottomRight"
                className="d-flex align-items-center justify-content-center h-100 w-100"
              >
                <div
                  style={{
                    cursor: "pointer",
                    fontSize: "30px",
                  }}
                >
                  ...
                </div>
              </Dropdown>
            )}
          </div>
        </div>
      </div>
      <div className="feeds-card-content">
        <div>
          <span className="feeds-card-text">{postText}</span>
        </div>
        {postImage && (
          <div className="feeds-img-div mt-2">
            <img
              src={`${bucket_base_url}${postImage}`}
              className="w-100 h-100"
              style={{ borderRadius: "10px", objectFit: "cover" }}
              onClick={() => setShowImage(true)}
            />
          </div>
        )}
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ fontFamily: "Poppins", marginTop: 20 }}
        >
          <div className="d-flex align-items-center">
            <img src={dolLar} style={{ width: "25px" }} />
            <span style={{ marginLeft: 5, fontSize: 13, fontWeight: "bold" }}>
              ${flatRate}
            </span>
          </div>
          <div>
            <img src={skillImage} style={{ width: "21px" }} />
            <span style={{ marginLeft: 5, fontSize: 13, fontWeight: "bold" }}>
              {skill}
            </span>
          </div>
          {detail ? (
            <div
              onClick={handleOpenGoogleMaps}
              style={{ cursor: "pointer", width: "100px" }}
            >
              <img src={locationPoint} style={{ width: "30px" }} />
              {item?.city === null || item?.city === "" ? (
                <></>
              ) : (
                <span
                  style={{
                    marginLeft: 5,
                    fontSize: 13,
                    fontFamily: "Poppins",
                    color: "#f77e0b",
                    textDecoration: "underline",
                  }}
                >
                  {truncateString(item?.city, 8)}
                </span>
              )}
            </div>
          ) : (
            <div style={{ cursor: "pointer" }} onClick={seeDetail}>
              <img src={threeDot} style={{ width: "30px" }} />
              <span
                style={{
                  marginLeft: 5,
                  fontSize: 13,
                  fontWeight: "bold",
                  fontFamily: "Poppins",
                }}
              >
                <FormattedMessage id="see_details" />
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="feed-divider2"></div>
      <div className="d-flex justify-content-around align-items-center pb-2 pt-2">
        {userData?.user_type === "contractor" && (
          <div
            className={`d-flex align-items-center ${
              isBlinking ? "blinking" : ""
            }`}
            onClick={() => {
              if (item?.contractor?.id === userTypeData?.id) {
                setTab("like");
              }
              setBlinking(true);
            }}
          >
            <span
              style={{
                marginLeft: 5,
                fontSize: 15,
                color: "rgb(137, 136, 136)",
                cursor: "pointer",
              }}
            >
              {`${props.intl.formatMessage({ id: "intrested_gigster" })} ${
                likeCount > 0 ? `(${likeCount})` : ""
              }`}
            </span>
          </div>
        )}
        {userData?.user_type === "tradesman" && (
          <div
            onClick={handleLikeDislike}
            className={`d-flex align-items-center ${
              isBlinking ? "blinking" : ""
            }`}
          >
            {userData?.user_type === "tradesman" && isLike ? (
              <img src={likefill} style={{ width: "25px" }} />
            ) : (
              <img src={like} style={{ width: "25px" }} />
            )}
            <span
              style={{
                marginLeft: 5,
                fontSize: 15,
                color: "rgb(137, 136, 136)",
                cursor: "pointer",
              }}
            >
              {`${props.intl.formatMessage({ id: "intrested" })} ${
                !isLike && likeCount > 0 ? `(${likeCount})` : ""
              }`}
            </span>
          </div>
        )}
        <div
          onClick={() => {
            if (detail) {
              setTab("comment");
            } else {
              navigate("/post-details", {
                state: {
                  data: item,
                  index: index,
                },
              });
            }
          }}
        >
          <img src={comment} style={{ width: "25px" }} />
          <span
            style={{
              marginLeft: 5,
              fontSize: 15,
              color: "rgb(137, 136, 136)",
              cursor: "pointer",
            }}
          >
            {`${props.intl.formatMessage({ id: "comments" })} ${
              detail ? `(${commentdetailsCount})` : `(${commentsCount})`
            }`}
          </span>
        </div>
      </div>
      <div className="feeds-card-content">
        {detail && tab !== "" && tab === "like"
          ? !isEmpty(likes) &&
            likes.map((item) => {
              return (
                <div key={item?.id}>
                  <CommentSection
                    ProfilePic={
                      item?.contractor
                        ? item?.contractor?.profile_picture
                        : item?.tradesman?.profile_picture
                    }
                    comment={item?.comment}
                    firstName={
                      item?.contractor
                        ? item?.contractor?.user?.first_name
                        : item?.tradesman?.user?.first_name
                    }
                    lastName={
                      item?.contractor
                        ? item?.contractor?.user?.last_name
                        : item?.tradesman?.user?.last_name
                    }
                  />
                </div>
              );
            })
          : detail &&
            tab !== "" &&
            tab === "comment" &&
            !isEmpty(comments) &&
            comments?.map((item, index) => {
              return (
                <div key={item?.id}>
                  <CommentSection
                    ProfilePic={
                      item?.contractor
                        ? item?.contractor?.profile_picture
                        : item?.tradesman?.profile_picture
                    }
                    comment={item?.comment}
                    firstName={
                      item?.contractor
                        ? item?.contractor?.user?.first_name
                        : item?.tradesman?.user?.first_name
                    }
                    lastName={
                      item?.contractor
                        ? item?.contractor?.user?.last_name
                        : item?.tradesman?.user?.last_name
                    }
                  />
                </div>
              );
            })}
      </div>
      {detail && (
        <div className="row pb-4">
          <div className="w-75 d-flex justify-content-end align-items-center">
            <input
              className="comment-input"
              placeholder={`${props.intl.formatMessage({
                id: "enter_comment_here",
              })}`}
              onChange={(e) => setComment(e.target.value)}
              value={commentValue}
            />
          </div>
          <div className="w-25 d-flex justify-content-start align-items-center">
            <Button className="button-send" onClick={handleCommentPress}>
              <FormattedMessage id="send" />
            </Button>
          </div>
        </div>
      )}
      {showImage && (
        <ImageViewModal
          showModal={showImage}
          setShowModal={setShowImage}
          images={[viewImage]}
          index={0}
        />
      )}
      {showCompleteProfile && (
        <CompleteProfileModal
          showModal={userTypeData?.health < 100 ? true : false}
          setShowModal={setShowCompleteProfile}
          currentUser={userTypeData}
        />
      )}
    </div>
  );
};
export default injectIntl(FeedsCard);
