/** @format */

import * as Actions from "../actions/ActionTypes";
const CommonReducer = (
  state = {
    bannerImageList: [],
    profileVideosList: [],
    userData: {},
    IsFromSignUP: false,
    profile: [],
    videoUrl: "",
    skillsList: [],
    fullUser: [],
    logoUrl: "",
    profileUrl: "",
    reviewsList: [],
    AllSkills: [],
    userSkill: [],
    imagesData: [],
    storiesList: [],
    searchedProfileList: [],
    newUser: [],
    likedUser: [],
    singleStoryModal: false,
    storyIndex: 0,
    singleYoutubeModal: false,
    selectedProfile: [],
    unSubscribedUser: false,
  },
  action
) => {
  switch (action.type) {
    case Actions.GET_BANNER_IMAGES:
      return Object.assign({}, state, {
        isLoading: true,
        bannerImageList: [],
        error: undefined,
      });
    case Actions.GET_BANNER_IMAGES_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        bannerImageList: action.bannerList,
        error: undefined,
      });
    case Actions.GET_BANNER_IMAGES_ERROR:
      return Object.assign({}, state, {
        isLoading: false,
        bannerImageList: [],
        error: action.error,
      });

    case Actions.GET_PROFILE_VIDEOS:
      return Object.assign({}, state, {
        isLoading: true,
        profileVideosList: [],
        error: undefined,
      });
    case Actions.GET_PROFILE_VIDEOS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        profileVideosList: action.videoList,
        error: undefined,
      });
    case Actions.GET_PROFILE_VIDEOS_ERRORS:
      return Object.assign({}, state, {
        isLoading: false,
        profileVideosList: [],
        error: action.error,
      });

    case Actions.GET_USER_DATA:
      return Object.assign({}, state, {
        isLoading: true,
        userData: {},
        error: undefined,
      });
    case Actions.GET_USER_DATA_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        userData: action.userData,
        error: undefined,
      });
    case Actions.GET_USER_DATA_ERRORS:
      return Object.assign({}, state, {
        isLoading: false,
        userData: {},
        error: action.error,
      });

    case Actions.SET_FROM_SIGN_UP_FALSE: {
      return Object.assign({}, state, {
        IsFromSignUP: false,
      });
    }
    case Actions.SET_FROM_SIGN_UP_TRUE:
      return Object.assign({}, state, {
        IsFromSignUP: true,
      });

    case Actions.SET_SELECTED_PROFILE:
      return Object.assign({}, state, {
        profile: action.user,
      });
    case Actions.DELETE_SELECTED_PROFILE:
      return Object.assign({}, state, {
        profile: {},
      });

    case Actions.SET_VIDEOS_URL:
      return Object.assign({}, state, {
        videoUrl: action.url,
      });

    case Actions.SET_ALL_SKILLS:
      return Object.assign({}, state, {
        skillsList: action.skills,
      });

    case Actions.SET_FULL_USER_DATA:
      return Object.assign({}, state, {
        fullUser: action.user?.data[0],
      });

    case Actions.SET_LOGO_URL:
      return Object.assign({}, state, {
        logoUrl: action.url,
      });
    case Actions.SET_PROFILE_URL:
      return Object.assign({}, state, {
        profileUrl: action.profileUrl,
      });

    case Actions.SET_REVIEW_LIST:
      return Object.assign({}, state, {
        reviewsList: action.reviews,
      });
    case Actions.SET_SKILLS_LIST:
      return Object.assign({}, state, {
        AllSkills: action.skill,
      });
    case Actions.SET_USER_SKILLS_LIST:
      return Object.assign({}, state, {
        userSkill: action.userSkill,
      });

    case Actions.SET_IMAGES_DATA_LIST:
      return Object.assign({}, state, [
        (imagesData[action.index] = action.image),
      ]);

    case Actions.SET_STORIES_LIST:
      return Object.assign({}, state, {
        storiesList: action.stories,
      });
    case Actions.SET_STORIES_MODAL:
      return Object.assign({}, state, {
        singleStoryModal: action.stories,
      });
    case Actions.SET_YOUTUBE_MODAL:
      return Object.assign({}, state, {
        singleYoutubeModal: action.stories,
      });
    case Actions.SET_STORIES_INDEX:
      return Object.assign({}, state, {
        storyIndex: action.stories,
      });

    case Actions.SEARCHED_PROFILES_LIST:
      return Object.assign({}, state, {
        searchedProfileList: action.profile,
      });

    case Actions.SET_CURRENT_USER:
      return Object.assign({}, state, {
        newUser: action.newUser,
      });
    case Actions.LIKED_USER_LIST:
      return Object.assign({}, state, {
        likedUser: action.likedUser,
      });
    case Actions.SET_UNSUBSCRIBED_USER:
      return Object.assign({}, state, {
        unSubscribedUser: action.data,
      });

    default:
      return state;
  }
};

export default CommonReducer;
