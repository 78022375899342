import { setW9formData } from "../../actions/W9formActions";
import { SimpleCatchError } from "../../utils/Helper";
import { Axios } from "../../utils/axiosMiddleware";
export const getFormData = async (path, token, dispatch) => {
  try {
    if (
      ["contractor", "tradesman"].includes(token?.user_type) &&
      path === "receive"
    ) {
      const response = await Axios.get(
        `gigwage/receiving-account/w9/${token?.user_id}`
      );
      dispatch(setW9formData(response?.data));
    } else if (
      (["contractor", "tradesman"].includes(token?.user_type) &&
        path === "sender") ||
      token?.user_type === "homeowner"
    ) {
      const response = await Axios.get("gigwage/sending-account/w9");
      dispatch(setW9formData(response?.data));
    }
  } catch (e) {
    dispatch(setW9formData({}));
    SimpleCatchError(e);
  }
};
