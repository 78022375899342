import { Axios, formAxios } from "../../utils/axiosMiddleware";
import { catchError, SimpleCatchError } from "../../utils/Helper";

export const CustomerCreation = async (body, path) => {
  try {
    if (path === "Edit") {
      await formAxios.patch(`gigwage/sending-account`, body);
    } else {
      await formAxios.post(`gigwage/sending-account`, body);
    }
    window.location.href = "/";
  } catch (e) {
    await catchError(e);
  }
};

export const AddDebitCard = async (body) => {
  try {
    await Axios.post("gigwage/receiving-account/debit-card", body);
  } catch (e) {
    SimpleCatchError(e);
  }
};
