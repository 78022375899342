import { message } from "antd";
import { Axios } from "../../utils/axiosMiddleware";

export const PaymentScreenThreeApi = async (body) => {
  try {
    await Axios.post(`gigwage/receiving/bank-account`, body);
    window.location.href = "/";
  } catch (e) {
    if (e.message === "Network Error") {
      message.error(`${e.message}`);
    } else {
      message.error(`${e.response.data.message}`);
    }
  }
};
