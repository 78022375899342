import { Button, Modal, Tooltip } from "antd";
import React, { useState } from "react";
import "./style.css";
import { FontSizeOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
export const TextOverImageModal = ({
  fileUrl,
  open,
  setOpen,
  text,
  setText,
  handleSubmit,
  fromProfile,
  data,
  setData,
}) => {
  const [typing, setTyping] = useState(false);
  const [lineCount, setLineCount] = useState(1);
  const [imageText, setImageText] = useState(null);
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && lineCount > 3) {
      event.preventDefault();
    }
  };
  const autoResize = (textarea) => {
    textarea.style.height = textarea.scrollHeight + "px";
  };
  const handleChange = (e) => {
    if (fromProfile) {
      setImageText(e.target.value);
    } else {
      setText(e.target.value);
    }
    autoResize(e.target);
    const textarea = e.target;
    const lineHeight = parseInt(getComputedStyle(textarea).lineHeight);
    const lines = Math.floor(textarea.scrollHeight / lineHeight);
    setLineCount(lines);
  };
  const onConfirm = async () => {
    if (fromProfile) {
      const newData = [...data];
      newData[data?.length - 1].text = imageText;
      setData(newData);
      setOpen(false);
    } else {
      setText(null);
      setOpen(false);
      await handleSubmit();
    }
  };
  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      footer={null}
      width={450}
      destroyOnClose={true}
    >
      <div>
        <div className="main-wrapper-div">
          <div
            className="absolute-text-icon"
            onClick={() => setTyping(!typing)}
          >
            <Tooltip title="Add Text">
              <FontSizeOutlined />
            </Tooltip>
          </div>

          {typing && (
            <div className="typing-wrapper-div">
              <textarea
                className="glassInput"
                onChange={(e) => handleChange(e)}
                onKeyDown={handleKeyDown}
                autoFocus
                rows={1}
                maxLength={90}
              />
            </div>
          )}
          <img
            src={fromProfile ? data[data?.length - 1]?.key : fileUrl}
            style={{ width: "100%" }}
          />
        </div>
        <div className="w-100">
          <Button onClick={onConfirm} className="button-continue">
            <FormattedMessage id="confirm" />
          </Button>
        </div>
      </div>
    </Modal>
  );
};
