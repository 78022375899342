import React, { useEffect, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import BankAccountCard from "../../components/BankInfo/BankAccountCard";
import { getAccountList } from "../../api/bankApi/BankAccountList";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/Header/Header";
import { isEmpty } from "lodash";
import "./BankInfo.css";
import Loader from "../../components/GeneralComponents";
import edit from "../../assets/images/Edit.png";
import { BackButton } from "../../components/General/BackButton";
import { useNavigate } from "react-router";
import { Modal } from "antd";
const BankAccountList = (props) => {
  const [disableAccount, setDisableAccount] = useState(false);
  const [loading, setLoading] = useState(false);
  const userTypeData = useSelector((state) => state?.common?.fullUser);
  const userData = JSON.parse(localStorage.getItem("data"));
  const bankAccountList = useSelector(
    (state) => state?.bankInfo?.bankAccountList
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (userTypeData) {
      AccountListing(userTypeData?.id);
    }
  }, []);
  const AccountListing = async (id) => {
    setLoading(true);
    await getAccountList(id, dispatch);
    setLoading(false);
  };
  const handleEditAccount = () => {
    if (isEmpty(bankAccountList)) {
      navigate("/bank-info");
    } else {
      setDisableAccount(true);
    }
  };

  return (
    <>
      {loading && (
        <div className="search-loader">
          <Loader />
        </div>
      )}
      <BackButton />
      <div className="container paymentscreen">
        <Header />
        <div className="paymentscreen-inner">
          <div className="d-flex">
            <div className="banklist-heading d-flex justify-content-end">
              <FormattedMessage id="bank_accounts" />
            </div>
            <div className="add-button d-flex justify-content-end mr-1">
              <img src={edit} onClick={handleEditAccount} />
            </div>
          </div>
          {!isEmpty(bankAccountList) &&
            bankAccountList.map((item) => {
              return (
                <BankAccountCard
                  userId={userTypeData?.id}
                  title={item?.name}
                  accNumber={item?.last4}
                  active={item?.deactivated_at === null}
                  routingNumber={item?.routing_number}
                  account_type={item?.account_type}
                  setLoading={setLoading}
                />
              );
            })}
        </div>
      </div>
      <Modal
        open={disableAccount}
        onCancel={() => setDisableAccount(false)}
        destroyOnClose={true}
        footer={null}
      >
        <div>
          <span className="disclaimer">
            <FormattedMessage id="disclaimer" />
          </span>
          <span className="disclaimer-text">
            <FormattedMessage id="first_deactivate" />
          </span>
        </div>
      </Modal>
    </>
  );
};
export default injectIntl(BankAccountList);
